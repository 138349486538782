import { api } from './api';
import { MachineForCreation, MachineParamsForCreation } from '../models/machine.tsx';
import { Establishment } from '../models/establishment.tsx';

export const MachinesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createMachine: builder.mutation<
      Establishment,
      { establishmentId: number; machine: MachineForCreation }
    >({
      query: (arg) => {
        const { establishmentId, machine } = arg;
        return {
          url: `/establishments/${establishmentId}/manufacturing-machines`,
          method: 'POST',
          body: machine
        };
      }
    }),

    createMachineParams: builder.mutation<
      Establishment,
      { establishmentId: number; machineId: number; machineParams: MachineParamsForCreation }
    >({
      query: (arg) => {
        const { establishmentId, machineId, machineParams } = arg;
        return {
          url: `/establishments/${establishmentId}/manufacturing-machines/${machineId}/params`,
          method: 'POST',
          body: machineParams
        };
      },
      invalidatesTags: ['UserInfo']
    })
  })
});

export const { useCreateMachineMutation, useCreateMachineParamsMutation } = MachinesApi;
