import styles from './exit-modal.module.scss';
import { Button, Modal, Text } from '@platform-storybook/circlestorybook';
import { ColorPropsEnum } from '../../enum/color.enum.ts';
import { history } from '../../models/history.tsx';
import { useTranslation } from 'react-i18next';
import { resetAll } from '../../store/index.tsx';
import { useAppDispatch } from '../../hooks/hooks.tsx';
import { useDeleteOrder } from '../../hooks/useDeleteOrder.tsx';

type props = {
  orderNumber?: string;
  patientReference?: string;
  isOpened: boolean;
  redirectUrl: string;
  onClose: () => void;
};

const ExitModal = ({ orderNumber, patientReference, isOpened, redirectUrl, onClose }: props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['order']);

  const onDeleteSuccess = () => {
    if (history.navigate) {
      dispatch(resetAll());
      history.navigate(redirectUrl);
    }
  };

  const { deleteOrderHandler, isLoading } = useDeleteOrder(onDeleteSuccess);
  return (
    <Modal
      isOpened={isOpened}
      onClose={onClose}
      title={t('editOrder.exitModal.title')}
      className={styles['exit-modal__content']}>
      <>
        <Text
          textAlign="center-align"
          label={t('editOrder.exitModal.subtitle')}
          color={ColorPropsEnum.GREY}
          className={styles['exit-modal__content__helper']}
        />
        <Text
          bold
          textAlign="center-align"
          label={t('editOrder.exitModal.message')}
          color={ColorPropsEnum.GREY}
          className={styles['exit-modal__content__confirmation']}
        />
        <Button
          data-cy="treatment-exit-button"
          label={t('editOrder.exitModal.button')}
          variant={ColorPropsEnum.ALERT}
          iconLeft="fa-trash"
          isLoading={isLoading}
          className={styles['exit-modal__button']}
          onClick={() => {
            if (orderNumber) {
              deleteOrderHandler(orderNumber, patientReference);
            } else {
              // For the files step, when treatment is not created. Should be changed very soon
              if (history.navigate) {
                dispatch(resetAll());
                history.navigate(redirectUrl);
              }
            }
          }}
        />
      </>
    </Modal>
  );
};

export default ExitModal;
