import ReactDOM from 'react-dom';
import styles from './loading-modal.module.scss';
import { useTranslation } from 'react-i18next';
import { Loader, Text } from '@platform-storybook/circlestorybook';
import { ColorPropsEnum } from '../../enum/color.enum';
import { useEffect, useState } from 'react';

type Props = {
  isOpened: boolean;
};

const LoadingModal = ({ isOpened }: Props) => {
  const { t } = useTranslation(['home']);
  const [shouldRender, setRender] = useState(isOpened);
  const loadingModalRoot = document.getElementById('loading-modal-root');

  useEffect(() => {
    if (isOpened) {
      setRender(true);
    }
  }, [isOpened]);

  const onAnimationEnd = () => {
    if (!isOpened) {
      setRender(false);
    }
  };

  return (
    <>
      {shouldRender &&
        loadingModalRoot &&
        ReactDOM.createPortal(
          <>
            <div
              className={[
                styles['loading-modal__background'],
                [
                  isOpened
                    ? styles['loading-modal__background--fade-in-modal']
                    : styles['loading-modal__background--fade-out-modal']
                ]
              ].join(' ')}
              onAnimationEnd={onAnimationEnd}></div>
            <div
              className={[
                styles['loading-modal'],
                [isOpened ? styles['loading-modal--opened'] : styles['loading-modal--closed']]
              ].join(' ')}
              data-cy="loading-modal">
              <Text
                label={t('loadingModal.description')}
                color={ColorPropsEnum.GREY}
                className={styles['loading-modal__description']}
              />
              <Loader />
            </div>
          </>,
          loadingModalRoot
        )}
    </>
  );
};

export default LoadingModal;
