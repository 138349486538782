import { useTranslation } from 'react-i18next';
import styles from './plan-step.module.scss';
import {
  Button,
  TeethMap,
  Text,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';
import { useEffect, useState } from 'react';
import { useGetConnectedUserQuery } from '../../../../services/user.api.services.ts';
import { DentalNotation } from '../../../../enum/user.ts';
import { ColorPropsEnum } from '../../../../enum/color.enum.ts';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks.tsx';
import {
  currentItemSelector,
  notCompatibleArchProductSelector,
  notCompatibleToothProductsSelector
} from '../../../../store/orders/orders.selectors.tsx';
import {
  OrderForUpdate,
  OrderItemComponentForCreation,
  OrderItemForCreation
} from '../../../../models/order.tsx';
import { ComponentType, PositionKey, ToothShadeEnum } from '../../../../enum/component.ts';
import { CursorEnum, getCursors, MapModeEnum } from '../../../../enum/map.enum.ts';
import { StumpMode } from '../../../../enum/product.enum.ts';
import {
  useGetOneOrderQuery,
  usePatchOrderMutation,
  useSubmitOrderMutation
} from '../../../../services/orders-api.services.ts';
import { defaultProductIconUrl } from '../../../../utils/utils.tsx';
import { ordersActions } from '../../../../store/orders/orders.reducer.tsx';
import { mapActions } from '../../../../store/map/map.reducer.tsx';
import {
  chairsideCursorsSelector,
  lineAndNumberColorsSelector,
  mapContextSelector,
  mapSvgLayerSelector,
  selectedTeethSelector,
  teethShadesSelector
} from '../../../../store/map/map.selectors.tsx';
import {
  MapClickPayload,
  MaterialBubble,
  ProductBubble,
  ShadeBubble
} from '../../../../models/map.tsx';
import { PositionKeyString } from '../../../../models/position.tsx';
import { getFamilyColor } from '../../../../features/order-manager/teeth-map/utils.tsx';
import MapTools from './MapTools/MapTools.tsx';
import ModeBoxPlan from './ModeBoxPlan/ModeBoxPlan.tsx';

type Props = {
  orderNumber: string;
  nextCallback: () => void;
};
const PlanStep = ({ orderNumber, nextCallback }: Props) => {
  const { t } = useTranslation(['order']);
  const dispatch = useAppDispatch();
  const [error, setError] = useState<boolean>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { data: connectedUser } = useGetConnectedUserQuery();
  const [patchOrder] = usePatchOrderMutation();
  const [submitOrder] = useSubmitOrderMutation();
  const { data: order } = useGetOneOrderQuery(orderNumber as string);

  const currentItem = useAppSelector(currentItemSelector);
  const currentProduct = currentItem?.product;
  const mapContext = useAppSelector(mapContextSelector);
  const componentTooth = currentProduct?.components?.find(
    (component) => component.componentType === ComponentType.TOOTH
  );
  const notCompatibleToothProducts = useAppSelector(notCompatibleToothProductsSelector);
  const notCompatibleArchProduct = useAppSelector(notCompatibleArchProductSelector);
  const mapSvgLayers = useAppSelector(mapSvgLayerSelector); // EXTRACT, INLAY, STUMP ...
  const selectedTeeth = useAppSelector(selectedTeethSelector);
  const selectedShade = selectedTeeth[0]?.teethShade;
  const selectedTeethMachineMaterialInfo = selectedTeeth[0]?.machineMaterialInfo;
  const cursors = useAppSelector(chairsideCursorsSelector);
  const teethShades = useAppSelector(teethShadesSelector);
  const lineAndNumberColors = useAppSelector(lineAndNumberColorsSelector);

  useEffect((): void => {
    if (currentProduct) {
      dispatch(
        mapActions.setMapContext({
          productId: currentProduct.id,
          teethSelectionMode: currentProduct.teethMode,
          teethComponentRule: componentTooth?.rule,
          productRule: currentProduct.productRule,
          isOneDesign: currentProduct.isOneDesign,
          productCompatibilities: {
            notCompatibleToothProducts: notCompatibleToothProducts,
            notCompatibleArchProduct: notCompatibleArchProduct
          }
        })
      );
    }
  }, [currentProduct]);

  const canSubmitOrder: boolean =
    selectedTeeth.length === 1 &&
    Object.values(ToothShadeEnum).includes(selectedTeeth[0].teethShade as ToothShadeEnum) &&
    selectedTeethMachineMaterialInfo !== undefined;

  const submit = async (event: React.FormEvent): Promise<void> => {
    if (event) event.preventDefault();
    if (!canSubmitOrder) {
      setError(true);
      return;
    }

    if ((order as OrderForUpdate)?.orderNumber) {
      setIsSaving(true);
      const orderToUpdate: OrderForUpdate = {
        orderNumber: (order as OrderForUpdate).orderNumber,
        patient: { reference: order?.patient?.reference },
        modifiedByLab: (order as OrderForUpdate)?.modifiedByLab,
        items: (order as OrderForUpdate)?.items || [],
        tags: (order as OrderForUpdate)?.tags,
        submissionDate: (order as OrderForUpdate)?.submissionDate
      };
      const updatedOrderWithDiagnostic = addDiagnostic(orderToUpdate);
      const finalOrderToUpdate = addOrderItems(updatedOrderWithDiagnostic);

      await patchOrder(finalOrderToUpdate);
      await submitOrder((order as OrderForUpdate).orderNumber as string);
      setIsSaving(false);
      nextCallback();
    }
  };

  const addDiagnostic = (order: OrderForUpdate): OrderForUpdate => {
    const tooth =
      selectedTeeth.length === 1 ? +(selectedTeeth[0].notation as PositionKeyString) : undefined;

    // if a tooth exists add the patient: {...}
    return {
      ...order,
      ...(tooth
        ? {
            patient: {
              diagnostic: {
                naturalStumps:
                  currentProduct?.stumpMode === StumpMode.ALWAYS ? [tooth as PositionKey] : []
              }
            }
          }
        : {})
    };
  };

  const getMachineMaterialByParamId = (paramId: number) => {
    const machines = connectedUser?.clinic?.manufacturingMachines ?? [];

    for (const machine of machines) {
      if (machine.params) {
        const param = machine.params.find((param) => param.id === paramId);
        if (param) {
          return {
            name: machine.name,
            ipAddress: machine.ipAddress,
            port: machine.port,
            manufacturingProcess: machine.manufacturingProcess,
            computerAidedManufacturing: machine.computerAidedManufacturing,
            // We spread the params and pass the id to undefined because we don't need it in the object
            param: { ...param, id: undefined }
          };
        }
      }
    }

    return null;
  };

  const addOrderItems = (order: OrderForUpdate): OrderForUpdate => {
    if (currentItem && selectedTeethMachineMaterialInfo) {
      // get manufacturingMachine and params
      const manufacturingMachine = getMachineMaterialByParamId(
        selectedTeethMachineMaterialInfo?.id
      );

      const toothComponent = currentItem.product?.components?.find((component) =>
        [ComponentType.PARTIAL_TOOTH, ComponentType.TOOTH].includes(component.componentType)
      );
      const componentShade = toothComponent?.shades?.find((shade) => shade.code === selectedShade);
      const item: OrderItemForCreation = {
        product: currentItem.product,
        tags: [],
        itemComponents: currentItem.product.components?.map((component) => {
          if (
            [ComponentType.PARTIAL_TOOTH, ComponentType.TOOTH].includes(component.componentType)
          ) {
            return {
              teethPositions: [+(selectedTeeth[0].notation as PositionKeyString)] as PositionKey[],
              toothStratificationTechnique: component?.toothStratificationTechniques?.[0],
              componentType: component.componentType,
              material: component?.materials?.[0],
              shade: componentShade,
              shape: component?.shapes?.[0],
              manufacturingMachine: manufacturingMachine
            };
          }
        }) as OrderItemComponentForCreation[]
      };
      order.items = [item];
    }
    return order;
  };

  const getBubbles = () => {
    const bubbles: { [position: number]: ProductBubble | ShadeBubble | MaterialBubble } = {};

    selectedTeeth.forEach((position) => {
      const positionNumber = +(position.notation as PositionKeyString);
      if (mapContext?.mode === MapModeEnum.PROSTHESIS) {
        bubbles[positionNumber] = {
          ...position.bubble,
          'data-cy': `map-product-bubble-${position.notation}`
        } as ProductBubble;
      } else if (mapContext?.mode === MapModeEnum.SHADES) {
        bubbles[positionNumber] = {
          type: 'text',
          size: 'small',
          backgroundColor:
            ColorPropsEnum[
              `${(selectedShade as string)?.toUpperCase()}` as keyof typeof ColorPropsEnum
            ] ?? ColorPropsEnum.GREY_100,
          labelInside: selectedShade?.replace('_', '.') as string,
          color: ColorPropsEnum.WHITE,
          'data-cy': `map-shade-bubble-${position.notation}`
        };
      } else if (mapContext?.mode === MapModeEnum.MATERIAL) {
        bubbles[positionNumber] = {
          type: 'text',
          size: 'small',
          backgroundColor: ColorPropsEnum.GREY_100,
          labelInside: selectedTeethMachineMaterialInfo
            ? t(`material.abbreviation.${selectedTeethMachineMaterialInfo?.material}`, {
                ns: 'component'
              })
            : '',
          color: ColorPropsEnum.WHITE,
          'data-cy': `map-material-bubble-${position.notation}`
        };
      }
    });

    return bubbles;
  };

  const handleSelectPosition = (selectedTooth: PositionKeyString): void => {
    if (!currentItem) {
      return;
    }

    if (mapContext?.mode === MapModeEnum.PROSTHESIS) {
      setError(false);
      const mapClick: MapClickPayload = {
        notation: selectedTooth,
        familyColor: getFamilyColor(currentItem.product.family),
        stumpMode: currentItem.product?.stumpMode,
        componentTypes: currentItem.itemComponents!.map((item) => item.componentType),
        svgLayers: currentItem.product
          .components!.map((item) => item.svgLayer)
          .filter((item) => item) as string[],
        bubble: {
          type: 'image',
          size: 'small',
          backgroundColor: ColorPropsEnum.GREY_100,
          url: currentItem?.product.imageUrl ?? defaultProductIconUrl,
          color:
            ColorPropsEnum[
              `FAMILY_${currentItem?.product.family.toUpperCase()}` as keyof typeof ColorPropsEnum
            ]
        }
      };

      dispatch(ordersActions.setError(undefined));
      dispatch(mapActions.selectSinglePosition(mapClick));
    }
  };

  const getTooltipText = () => {
    if (mapContext?.mode === MapModeEnum.PROSTHESIS && selectedTeeth.length === 0) {
      return t('editOrder.plan.tooltip.selectProsthesis');
    }

    if (mapContext?.mode === MapModeEnum.PROSTHESIS && selectedTeeth.length > 0) {
      return t('editOrder.plan.tooltip.clickOnMaterialMode');
    }

    if (mapContext?.mode === MapModeEnum.MATERIAL && selectedTeethMachineMaterialInfo) {
      return t('editOrder.plan.tooltip.clickOnTeethShadeMode');
    }

    if (mapContext?.mode === MapModeEnum.MATERIAL) {
      return t('editOrder.plan.tooltip.selectMaterial');
    }

    if (mapContext?.mode === MapModeEnum.SHADES) {
      return t('editOrder.plan.tooltip.selectTeethShade');
    }
  };

  const submitButton = () => {
    return (
      <Button
        label={t('editOrder.plan.action')}
        type="button"
        onClick={submit}
        variant={ColorPropsEnum.SUCCESS}
        iconLeft="fa-chevron-right"
        isLoading={isSaving}
        isDisabled={!canSubmitOrder}
        data-cy="edit-order-plan-action"
      />
    );
  };

  return (
    <div className={styles['plan-step']}>
      <div className={styles['plan-step__content']}>
        <div className={styles['plan-step__content__intro']}>
          {!error && mapContext?.mode === MapModeEnum.PROSTHESIS && (
            <Text
              data-cy="prosthesis-intro"
              label={t('editOrder.plan.intro.prosthesis')}
              color={ColorPropsEnum.GREY}
              size="l"
            />
          )}
          {!error && mapContext?.mode === MapModeEnum.SHADES && (
            <Text
              data-cy="teeth-shade-intro"
              label={t('editOrder.plan.intro.teethShade')}
              color={ColorPropsEnum.GREY}
              size="l"
            />
          )}
          {!error && mapContext?.mode === MapModeEnum.MATERIAL && (
            <Text
              data-cy="teeth-machine-material-intro"
              label={t('editOrder.plan.intro.material')}
              color={ColorPropsEnum.GREY}
              size="l"
            />
          )}
          {error && (
            <Text label={t('editOrder.plan.selectProduct')} color={ColorPropsEnum.DANGER} />
          )}
        </div>
        <TeethMap
          className={styles['plan-step__content__teeth-map']}
          onClick={(tooth: PositionKeyString) => handleSelectPosition(tooth)}
          patientMouth={{ ...mapSvgLayers }}
          notation={connectedUser ? connectedUser?.dentalNotation : DentalNotation.ISO}
          cursors={currentItem ? cursors : getCursors(CursorEnum.NOT_ALLOWED)}
          teethShades={teethShades}
          lineAndNumberColors={lineAndNumberColors}
          displayShadows={true}
          isStrokeTeeth={true}
          defaultShade={ColorPropsEnum.GREY_200}
          bubbles={getBubbles()}
        />
        <div className={styles['plan-step__content__modebox']}>
          <ModeBoxPlan />
        </div>
        <div className={styles['plan-step__content__map-tools']}>
          <MapTools
            toolMode={mapContext?.mode}
            currentProduct={currentProduct}
            selectedShade={selectedShade}
            currentTeethMachineMaterialInfo={selectedTeethMachineMaterialInfo}
          />
        </div>
      </div>
      <div className={styles['plan-step__button']}>
        {canSubmitOrder ? (
          submitButton()
        ) : (
          <Tooltip>
            <TooltipContent>{getTooltipText()}</TooltipContent>
            {submitButton()}
          </Tooltip>
        )}
      </div>
    </div>
  );
};
export default PlanStep;
