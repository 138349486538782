import { ToastType } from '../enum/feedback';
import { FileLabelEnum } from '../enum/file-label';
import { OrderFile } from '../models/order';
import { feedbackActions } from '../store/feedback/feedback.reducer';
import { ErrorCode } from '../enum/error';
import { t } from 'i18next';
import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { FeedBack } from '../models/feedback';

const allowedThumbprintExtensions = ['stl', 'ply', 'obj'];
const allowedImageExtensions = ['jpeg', 'jpg', 'png', 'gif', 'heic', 'heif', 'mtl', 'xls', 'xlsx'];
const allowedVideoExtensions = [
  'mov',
  'mp4',
  'avi',
  'wmf',
  'flv',
  'webm',
  'mpg',
  'mpa',
  'vob',
  'mpeg',
  'h264',
  'h265'
];

export const fileLabelsAllowed: Array<string> = Object.values([
  FileLabelEnum.UPPER,
  FileLabelEnum.LOWER,
  FileLabelEnum.OCCLUSION_KEY,
  FileLabelEnum.TEXTURE,
  FileLabelEnum.UPPER_PRESCAN,
  FileLabelEnum.LOWER_PRESCAN
]);

export const checkEmptyFile = (file: File): boolean => {
  return file?.size > 0;
};

export const checkFileAlreadyUploaded = (fileName: string, orderFiles: OrderFile[]): boolean => {
  return orderFiles.findIndex((file) => file.fileName === fileName) === -1;
};

export const isThumbprintExtension = (extension: string) =>
  allowedThumbprintExtensions.includes(extension?.toLowerCase());

export const checkFileExtensionAllowed = (extension: string): boolean => {
  return (
    isThumbprintExtension(extension) ||
    allowedVideoExtensions.includes(extension?.toLowerCase()) ||
    allowedImageExtensions.includes(extension?.toLowerCase())
  );
};

export const mapFileToOrderFile = (
  newFileToUpload: File,
  isLoading = false,
  fileLabel?: FileLabelEnum
) => {
  const extension = newFileToUpload.name.substring(newFileToUpload.name.lastIndexOf('.') + 1);
  const fileName = newFileToUpload.name.substring(0, newFileToUpload.name.lastIndexOf('.'));
  const newFile: OrderFile = {
    extension: extension,
    fileLabel: fileLabel,
    fileName: fileName,
    mimeType: 'application/octet-stream',
    isLoading: isLoading,
    data: newFileToUpload
  };
  return newFile;
};

export const getTextureFile = async (
  orderFiles: OrderFile[],
  file3D?: OrderFile
): Promise<OrderFile | undefined> => {
  if (file3D) {
    // Header attribute value to describe the texture file name in 3D file
    const textureFileHeader = 'comment TextureFile ';

    const header = await getFile3dHeader(file3D);
    if (header.includes(textureFileHeader)) {
      let textureFileName = header.substring(
        header.indexOf(textureFileHeader) + textureFileHeader.length
      );
      // Get texture file name in the file3D header
      textureFileName = textureFileName.substring(0, textureFileName.indexOf('\n'));
      // Search if the texture file has been uploaded
      const textureFile = orderFiles.filter(
        (file) => `${file.fileName}.${file.extension}` === textureFileName
      );
      if (textureFile?.length) {
        return textureFile[0];
      }
    }
  }
};

const getFile3dHeader = async (file3D: OrderFile): Promise<string> => {
  const response = await fetch(URL.createObjectURL(file3D.data as Blob));
  const data: string = await response.text();
  // Return only the header of the file
  return data.substring(0, data.indexOf('end_header'));
};

export const checkUploadingFile = (
  file: OrderFile,
  orderFiles: OrderFile[],
  dispatch: Dispatch<PayloadAction<FeedBack>>
): boolean => {
  if (!checkEmptyFile(file?.data as File)) {
    dispatch(
      feedbackActions.setToast({
        message: t(ErrorCode.ORDERS_FILE_EMPTY, { ns: 'error' }),
        type: ToastType.DANGER
      })
    );
    return false;
  }
  if (!checkFileAlreadyUploaded(file.fileName, orderFiles)) {
    dispatch(
      feedbackActions.setToast({
        message: t(ErrorCode.ORDERS_FILE_ALREADY_EXISTS, { ns: 'error' }),
        type: ToastType.DANGER
      })
    );
    return false;
  }
  if (!checkFileExtensionAllowed(file.extension)) {
    dispatch(
      feedbackActions.setToast({
        message: t(ErrorCode.ORDERS_FILE_NOT_ALLOWED, { ns: 'error', extension: file.extension }),
        type: ToastType.DANGER
      })
    );
    return false;
  }
  return true;
};

/**
 * Filter a list of files to keep only the ones to be uploaded during edit
 *
 * @param {OrderFile[]} filesState - The current state of files
 * @returns {OrderFile[]} - Filtered array containing all files to be uploaded
 */
export const getOrderFilesToUpload = (
  filesState: OrderFile[] // current
): OrderFile[] => {
  return filesState.filter((file) => !file.id);
};

export const areSameFiles = (file: OrderFile, otherFile: OrderFile): boolean =>
  file?.fileName === otherFile?.fileName &&
  file?.extension === otherFile?.extension &&
  file?.fileLabel === otherFile?.fileLabel &&
  file?.mimeType === otherFile?.mimeType;

export const areSameFileArrays = (filesArr1: OrderFile[], filesArr2: OrderFile[]): boolean => {
  if (filesArr1.length !== filesArr2.length) return false;

  // order files from backend doesn't have isLoading key
  const filterKeys = (obj: OrderFile) =>
    Object.fromEntries(Object.entries(obj).filter(([key]) => key !== 'isLoading'));

  const filteredFiles1 = filesArr1.map(filterKeys);
  const filteredFiles2 = filesArr2.map(filterKeys);

  return filteredFiles1.every((file1) =>
    filteredFiles2.some((file2) => areSameFiles(file1 as OrderFile, file2 as OrderFile))
  );
};

// Some files have "#" in their name (like files from Itero). We need to encode that, but encodeURI() does not work with "#" and encodeURIComponent() encode everything and gateway basUrl is added. So we build custom encoding to solve that.
export const encodeUrl = (url: string) => {
  return url.split('#').join('%23');
};
