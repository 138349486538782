import { useAppDispatch, useAppSelector } from './hooks/hooks.tsx';
import { isAuthenticatedSelector } from './store/auth/auth.selectors.tsx';
import RoutesDentist from './RoutesDentist.tsx';
import { getAuthState, isPlatformUser } from './services/firebase.services.tsx';
import { authActions } from './store/auth/auth.reducers.tsx';
import { User } from 'firebase/auth';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { history } from './models/history.tsx';
import {
  faCheck,
  faEnvelope,
  faHeadset,
  faList,
  faPencil
} from '@fortawesome/pro-regular-svg-icons';
import {
  faChevronLeft,
  faChevronRight,
  faPlus,
  faPaperPlane,
  faPenToSquare
} from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { saveUserStateForTests } from './utils/utils.tsx';

library.add(
  faList,
  faPencil,
  faHeadset,
  faXmark,
  faCheck,
  faPlus,
  faChevronRight,
  faChevronLeft,
  faEnvelope,
  faPaperPlane,
  faPenToSquare
);

function App() {
  const isAuthenticated = useAppSelector(isAuthenticatedSelector);
  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  history.navigate = useNavigate();
  history.location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (import.meta.env.VITE_NODE_ENV === 'test') {
      saveUserStateForTests(dispatch);
    } else {
      const saveUserState = async (user: User | null) => {
        // Redux loves so much simple object or complex string :)
        dispatch(
          authActions.saveUserState({
            firebaseUser: JSON.stringify(user),
            isAuthorized: !!user && (await isPlatformUser())
          })
        );
      };
      getAuthState(saveUserState);
    }
  }, [isAuthenticated]);

  return (
    <>
      <div id="portal-root"></div>
      <div id="side-bar-modal-root"></div>
      <div id="dropdown-root"></div>
      <div id="dropdown-menu-root"></div>
      <div id="tooltip-root"></div>
      <div id="dialog-root"></div>
      <div id="file-labeler-root"></div>
      <div id="modal-root"></div>
      <div id="loading-modal-root"></div>
      {isAuthenticated !== undefined && <RoutesDentist></RoutesDentist>}
    </>
  );
}

export default App;
