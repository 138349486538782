import { CellProps } from '@inovua/reactdatagrid-community/types';
import { Text } from '@platform-storybook/circlestorybook';
import { getColumnOptions } from '../features/datagrid/datagrid.utils.tsx';
import { useTranslation } from 'react-i18next';

export const useDatagridColumns = () => {
  const { t } = useTranslation(['profile', 'component']);

  return {
    colMachineName: {
      ...getColumnOptions('machineName', t('datagrid.columns.machine', { ns: 'profile' }), 2),
      showColumnMenuTool: false,
      minWidth: 60,
      cellDOMProps: () => ({
        style: {
          textAlign: 'left',
          textTransform: 'capitalize'
        }
      }),
      render: ({ value }: CellProps) => <Text label={value} />
    },

    colMaterial: {
      ...getColumnOptions('material', t('datagrid.columns.material', { ns: 'profile' }), 1),
      showColumnMenuTool: false,
      minWidth: 60,
      cellDOMProps: () => ({
        style: {
          textAlign: 'left',
          textTransform: 'capitalize'
        }
      }),
      render: ({ value }: CellProps) => <Text label={t(`material.${value}`, { ns: 'component' })} />
    },

    colBrandReference: {
      ...getColumnOptions(
        'brandReference',
        t('datagrid.columns.brandReference', { ns: 'profile' }),
        2
      ),
      showColumnMenuTool: false,
      minWidth: 60,
      cellDOMProps: () => ({
        style: {
          textAlign: 'left',
          textTransform: 'capitalize'
        }
      }),
      render: ({ value }: CellProps) => <Text label={value} />
    }
  };
};
