export enum WorkflowStepEnum {
  SUBMITTING = 'submitting',
  VALIDATION = 'validation',
  MODELING_DESIGN = 'modeling_design',
  DELIVERED = 'delivered'
}

export enum WorkflowComponentManufacturingStepEnum {
  TO_DESIGN = 'TO_DESIGN', // Component is in the design phase
  DESIGNED = 'DESIGNED', // Component has been designed
  TO_MANUFACTURE = 'TO_MANUFACTURE', // Component is in the manufacturing phase
  MANUFACTURED = 'MANUFACTURED' // Component has been manufactured
}

export const WorkflowManufacturingStepEnum = {
  TO_DESIGN: WorkflowComponentManufacturingStepEnum.TO_DESIGN,
  DESIGNED: WorkflowComponentManufacturingStepEnum.DESIGNED,
  TO_MANUFACTURE: WorkflowComponentManufacturingStepEnum.TO_MANUFACTURE,
  MANUFACTURED: WorkflowComponentManufacturingStepEnum.MANUFACTURED
};
Object.freeze(WorkflowManufacturingStepEnum);

export type WorkflowManufacturingStepEnum = WorkflowComponentManufacturingStepEnum;
