import { BubblePicto } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import styles from './map-tools.module.scss';
import { MachineMaterialInfo } from '../../../../../models/machine.tsx';
import { ColorPropsEnum } from '../../../../../enum/color.enum.ts';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../hooks/hooks.tsx';
import { extractMachineMaterialInfo } from '../../../../../utils/machine.utils.ts';
import { useGetConnectedUserQuery } from '../../../../../services/user.api.services.ts';
import { mapActions } from '../../../../../store/map/map.reducer.tsx';

type Props = {
  currentTeethMachineMaterialInfo?: MachineMaterialInfo;
};

const MachineMaterialBubbles = ({ currentTeethMachineMaterialInfo }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['treatments']);
  const { data: connectedUser } = useGetConnectedUserQuery();

  const machines = connectedUser?.clinic?.manufacturingMachines;
  const [machineMaterialInfos, setMachineMaterialInfos] = useState<MachineMaterialInfo[]>([]);

  useEffect(() => {
    if (machines && machines?.length > 0) {
      setMachineMaterialInfos(extractMachineMaterialInfo(machines));
    }
  }, [machines]);

  const onMachineMaterialBubbleClick = (machineMaterialInfo: MachineMaterialInfo): void => {
    dispatch(mapActions.setTeethMachineMaterialInfo(machineMaterialInfo));
  };

  return machineMaterialInfos?.map((machineMaterial) => (
    <div className={styles['map-tools__button-material']} key={machineMaterial.id}>
      <BubblePicto
        isClickable
        isHoverable
        type="text"
        hoverBorderColor={ColorPropsEnum.PRIMARY}
        onClick={() => onMachineMaterialBubbleClick(machineMaterial)}
        size="large"
        backgroundColor={
          machineMaterial.id === currentTeethMachineMaterialInfo?.id
            ? ColorPropsEnum.PURPLE_LIGHT
            : ColorPropsEnum.GREY_100
        }
        color={
          machineMaterial.id === currentTeethMachineMaterialInfo?.id
            ? ColorPropsEnum.PRIMARY
            : ColorPropsEnum.WHITE
        }
        mainTitle={machineMaterial.machineName}
        mainTitleSize="m"
        labelInside={t(`material.abbreviation.${machineMaterial.material}`, {
          ns: 'component'
        })}
        subtitle={machineMaterial.brandReference}
        data-cy={`material-machine-bubble-${machineMaterial.id}`}
      />
    </div>
  ));
};

export default MachineMaterialBubbles;
