import { TypeColWithNameProperty } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { TypeColWithNamePropertyPlatform } from '../../models/datagrid';

export const getColumnOptions = (
  name: string,
  header: string,
  defaultFlex?: number,
  sortable?: boolean // true by default
): Partial<TypeColWithNameProperty> => {
  let column: Partial<TypeColWithNamePropertyPlatform> = {
    name: name,
    headerAlign: 'start',
    header: header,
    showColumnMenuTool: true,
    showColumnMenuToolOnHover: false,
    enableColumnFilterContextMenu: false,
    cellDOMProps: () => ({
      style: {
        textAlign: 'left'
      }
    })
  };
  if (defaultFlex) {
    column = { ...column, defaultFlex: defaultFlex };
  }
  if (sortable !== undefined) {
    column = { ...column, sortable: sortable };
  }
  return column;
};
