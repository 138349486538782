import { Box, IconButton } from '@platform-storybook/circlestorybook';
import { ColorPropsEnum } from '../../enum/color.enum';
import styles from './tips.module.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TipCard, { ShortcutTip } from './TipCard.tsx';

type Props = {
  onCloseTips: () => void;
};

const leftClickImgUrl = '/image/tips/left-click.svg';
const rightClickImgUrl = '/image/tips/right-click.svg';

const Tips = ({ onCloseTips }: Props) => {
  const { t } = useTranslation(['tip']);
  const [selectedTip, setSelectedTip] = useState(0);

  const handleNextTipClick = () => {
    // Here we show the next tip in order, or the first one if we reached the end of the list.
    setSelectedTip(selectedTip < tipsList.length - 1 ? selectedTip + 1 : 0);
  };

  const handlePreviousTipClick = () => {
    // Here we show the previous tip in order, or the last one if we reached the beginning of the list.
    setSelectedTip(selectedTip > 0 ? selectedTip - 1 : tipsList.length - 1);
  };

  // Here we handle the current list of tips to be displayed in an array with a prop "rank", to determine the order of tips
  const tipsList: {
    rank: number;
    title: string;
    description: string;
    videoUrl?: string;
    shortcuts?: ShortcutTip[];
  }[] = [
    {
      rank: 0,
      title: t('marginLineTip.title'),
      description: t('marginLineTip.description'),
      videoUrl: 'https://storage.googleapis.com/public-circle-dental/video/tips-01.mp4',
      shortcuts: [
        {
          image: leftClickImgUrl,
          key: t('marginLineTip.key'),
          label: t('marginLineTip.addPoint')
        },
        {
          image: rightClickImgUrl,
          key: t('marginLineTip.key'),
          label: t('marginLineTip.removePoint')
        }
      ]
    },
    {
      rank: 1,
      title: t('marginLineMagnetizationTip.title'),
      description: t('marginLineMagnetizationTip.description'),
      videoUrl: 'https://storage.googleapis.com/public-circle-dental/video/tips-01.mp4',
      shortcuts: [
        {
          image: leftClickImgUrl,
          key: t('marginLineMagnetizationTip.key'),
          label: t('marginLineMagnetizationTip.action')
        }
      ]
    },
    {
      rank: 2,
      title: t('occlusalAlignmentTip.title'),
      description: t('occlusalAlignmentTip.description'),
      videoUrl: 'https://storage.googleapis.com/public-circle-dental/video/tips-01.mp4'
    },
    {
      rank: 3,
      title: t('teethIdentification.title'),
      description: t('teethIdentification.description'),
      videoUrl: 'https://storage.googleapis.com/public-circle-dental/video/tips-01.mp4'
    },
    {
      rank: 4,
      title: t('teethIdentificationSecond.title'),
      description: t('teethIdentificationSecond.description'),
      videoUrl: 'https://storage.googleapis.com/public-circle-dental/video/tips-01.mp4'
    }
  ];

  return (
    <div data-cy={'tips'} className={styles['tips']}>
      {tipsList.length > 1 && (
        <IconButton
          faIconClass="fa-chevron-left"
          iconSize="xs"
          radius="full"
          color="primary"
          category="outlined"
          onClick={() => handlePreviousTipClick()}
        />
      )}
      <div>
        <Box className={styles['tips__box']} color={ColorPropsEnum.WHITE} padding="spacing-0">
          {
            <TipCard
              videoUrl={tipsList[selectedTip]?.videoUrl}
              title={tipsList[selectedTip]?.title}
              description={tipsList[selectedTip]?.description}
              shortcuts={tipsList[selectedTip]?.shortcuts}
            />
          }
          <div className={styles['tips__box__close-button']}>
            <IconButton faIconClass="xmark" radius="full" iconSize="sm" onClick={onCloseTips} />
          </div>
        </Box>
        <div className={styles['tips__dots']}>
          {tipsList.length > 1 &&
            tipsList.map((tip) => (
              <div
                key={`tip${tip.rank}`}
                className={[
                  styles['tips__dots__dot'],
                  tip.rank === selectedTip && styles['tips__dots__dot--selected']
                ].join(' ')}></div>
            ))}
        </div>
      </div>
      {tipsList.length > 1 && (
        <IconButton
          faIconClass="fa-chevron-right"
          iconSize="xs"
          radius="full"
          color="primary"
          category="outlined"
          onClick={() => handleNextTipClick()}
        />
      )}
    </div>
  );
};

export default Tips;
