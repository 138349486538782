import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './reset-password-page.module.scss';
import { Box, Button, Link, Text, TextField } from '@platform-storybook/circlestorybook';
import { useAppDispatch } from '../../../hooks/hooks.tsx';
import { useTranslation } from 'react-i18next';
import { useResetPasswordMutation } from '../../../services/user.api.services.ts';
import { feedbackActions } from '../../../store/feedback/feedback.reducer';
import useForm from '../../../hooks/useForm.tsx';
import { StringObject } from '../../../models/common';
import { ColorPropsEnum } from '../../../enum/color.enum.ts';
import { ToastType } from '../../../enum/feedback.ts';
import { getUrlPrefix } from '../../../utils/partner.utils.ts';

const ResetPasswordPage = () => {
  const { t } = useTranslation(['resetPassword']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [resetPassword, { isLoading, isSuccess, isError }] = useResetPasswordMutation();

  const { partnerCode } = useParams();

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        feedbackActions.setToast({
          message: t('feedback'),
          type: ToastType.SUCCESS
        })
      );
      navigate(`${getUrlPrefix(partnerCode)}/login`);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      navigate(`${getUrlPrefix(partnerCode)}/login`);
    }
  }, [isError]);

  const onSubmit = async () => {
    if (values.email) {
      resetPassword(values.email as string);
    }
  };

  const validateEmail = (): StringObject => {
    const newErrors: StringObject = {};
    if (!values.email) {
      newErrors.email = 'empty';
    }

    return newErrors;
  };

  const { values, errors, handleSubmit, handleChange, handleBlur } = useForm(
    {
      email: ''
    },
    onSubmit,
    validateEmail
  );

  return (
    <div className={styles['reset-password-page']}>
      <section className={styles['reset-password-page__section']}>
        <Box
          padding="spacing-6"
          className={styles['login-page__section__box']}
          radius="large"
          color={ColorPropsEnum.WHITE}>
          <Text
            label={t('title')}
            type="title"
            size="m"
            className={styles['reset-password-page__section__title']}
            bold={true}
            color={ColorPropsEnum.PRIMARY}
          />

          <form
            noValidate
            id="reset-password-page__section__form"
            className={styles['reset-password-page__section__form']}
            data-cy={'reset-password-form'}
            onSubmit={handleSubmit}>
            <TextField
              label={t('email')}
              labelColor={ColorPropsEnum.GREY}
              id="email"
              name="email"
              value={values.email}
              placeholder="email@circle.com"
              type="email"
              variant={errors.email ? 'danger' : 'default'}
              helperText={errors?.email ? t(`error.${errors.email}`) : undefined}
              className={styles['reset-password-page__section__form__input']}
              onChange={handleChange}
              onBlur={handleBlur}
              data-cy={'reset-password-email'}
              radius="full"
            />

            <div className={styles['reset-password-page__section__form__button']}>
              <Button
                label={t('buttonSubmit')}
                isLoading={isLoading}
                type="submit"
                className={styles['reset-password-page__section__form__button__submit']}
                data-cy={'reset-password-submit-button'}
                variant={ColorPropsEnum.SUCCESS}
                iconLeft="paper-plane"
              />
            </div>
          </form>
        </Box>
        <div className={styles['reset-password-page__section__existing-account']}>
          <Text
            className={styles['reset-password-page__section__existing-account__text']}
            label={t('existingAccount')}
            size="s"
            color={ColorPropsEnum.GREY}
          />
          <Link
            href={`${getUrlPrefix(partnerCode)}/login`}
            label={t('signIn')}
            size="s"
            data-cy={'reset-password-redirect-to-login'}
          />
        </div>
      </section>
    </div>
  );
};

export default ResetPasswordPage;
