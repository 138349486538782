import { ColorPropsEnum } from '../../../../enum/color.enum';
import { OrderForList } from '../../../../models/order';
import moment from 'moment';
import {
  BubblePicto,
  Button,
  Text,
  Tooltip,
  TooltipContent,
  Skeleton
} from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { getBackgroundColor } from '../../../../utils/orders.utils';
import styles from './treatments-section.module.scss';
import { HOME_ORDER_TO_DISPLAY } from '../home';
import { useNavigate } from 'react-router-dom';
import { WorkflowStepEnum } from '../../../../enum/workflow-step';
import { useGetOrdersQuery } from '../../../../services/orders-api.services';
import FileSaver from 'file-saver';
import { useDownloadFileFromStorageMutation } from '../../../../services/files-api.services';
import { useGetProductionZipFileMutation } from '../../../../services/manufacturing-orders-api.services';
import { useEffect } from 'react';
import { ToastType } from '../../../../enum/feedback';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer';
import { useAppDispatch } from '../../../../hooks/hooks';

const TreatmentsSection = () => {
  const { t } = useTranslation(['home']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [downloadFromStorage] = useDownloadFileFromStorageMutation();
  const [getProductionZipFile, { isError: isErrorGetProductionZipFile }] =
    useGetProductionZipFileMutation();
  const {
    data: orders,
    isLoading: areOrdersLoading,
    isError: ordersError
  } = useGetOrdersQuery({
    page: 1,
    limit: HOME_ORDER_TO_DISPLAY,
    filtersQuery: `&filter.currentStep=$in:${WorkflowStepEnum.DELIVERED}`,
    searchQuery: ''
  });

  // If file not found
  useEffect(() => {
    if (isErrorGetProductionZipFile) {
      dispatch(
        feedbackActions.setToast({
          message: t('noProductionFiles', { ns: 'treatments' }),
          type: ToastType.DANGER
        })
      );
    }
  }, [isErrorGetProductionZipFile]);

  const handleDownload = async (order: OrderForList) => {
    if (!order) return;
    dispatch(
      feedbackActions.setToast({
        message: t('downloadProductionFiles.pending', { ns: 'treatments' }),
        type: ToastType.INFO
      })
    );
    const productionZipFileUrl = await getProductionZipFile(order.orderNumber);
    if (!productionZipFileUrl?.data) return;

    const zipFile = await downloadFromStorage({ url: productionZipFileUrl.data });
    if (zipFile?.data) {
      FileSaver.saveAs(zipFile.data as Blob, `${order.patientReference}.zip`);
    }
  };

  return (
    <div className={styles['treatments-section']} data-cy="home-treatments">
      <Text
        size="s"
        type="title"
        label={t('treatments.title').toUpperCase()}
        bold
        className={styles['treatments-section__title']}
        color={ColorPropsEnum.PRIMARY}
      />
      <div className={styles['treatments-section__list']}>
        {!ordersError &&
          orders?.data &&
          orders?.data.length > 0 &&
          !areOrdersLoading &&
          orders?.data.map((order) => (
            <div
              data-cy="home-treatment"
              key={`treatment-${order.id}`}
              className={styles['treatments-section__list__treatment']}>
              <div className={styles['treatments-section__list__treatment__infos']}>
                <BubblePicto
                  data-cy="treatment-picto"
                  url={order.items?.length > 0 ? order.items[0].product.imageUrl : undefined}
                  backgroundColor={getBackgroundColor(order)}
                  color={
                    order.items?.length > 0
                      ? ColorPropsEnum[
                          `FAMILY_${order.items[0].product.family.toUpperCase()}` as keyof typeof ColorPropsEnum
                        ]
                      : undefined
                  }
                />
                <div className={styles['treatments-section__list__treatment__infos__text']}>
                  <Text
                    size="m"
                    label={order.patientReference}
                    bold
                    className={styles['treatments-section__list__treatment__infos__text__patient']}
                    color={ColorPropsEnum.PRIMARY}
                  />
                  <div
                    className={styles['treatments-section__list__treatment__infos__text__other']}>
                    <Text
                      size="s"
                      label={moment(order.creationDate).format('MM/DD/YY')}
                      color={ColorPropsEnum.GREY}
                    />
                  </div>
                </div>
              </div>
              <div className={styles['treatments-section__list__treatment__step']}>
                <Tooltip>
                  <TooltipContent>
                    {t('downloadProductionFiles.tooltip', { ns: 'treatments' })}
                  </TooltipContent>
                  <Button
                    data-cy="home-treatment-download"
                    label={t('downloadProductionFiles.action', { ns: 'treatments' })}
                    variant={ColorPropsEnum.PRIMARY}
                    size={'s'}
                    isDisabled={order.currentStep !== WorkflowStepEnum.DELIVERED}
                    onClick={() => handleDownload(order)}
                  />
                </Tooltip>
              </div>
            </div>
          ))}
        {!ordersError && orders?.data.length === 0 && !areOrdersLoading && (
          <Text
            size="s"
            label={t('treatments.empty')}
            color={ColorPropsEnum.GREY}
            className={styles['treatments-section__list--empty']}
          />
        )}
        {areOrdersLoading &&
          !ordersError &&
          Array.from({ length: HOME_ORDER_TO_DISPLAY }).map((_, index) => (
            <Skeleton
              key={`skeleton-${index}`}
              type="square"
              backgroundColor={ColorPropsEnum.GREY_100}
              className={styles['treatments-section__list--skeleton']}
            />
          ))}
      </div>
      <Button
        data-cy="home-view-all-treatment"
        category="outlined"
        label={t('treatments.action')}
        className={styles['treatments-section__button']}
        onClick={() => {
          navigate('/treatments');
        }}
      />
    </div>
  );
};

export default TreatmentsSection;
