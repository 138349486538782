import styles from './edit-clinic-modal.module.scss';
import { Button, Dropdown, Modal, TextField } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import useForm from '../../../../hooks/useForm.tsx';
import { Country } from '../../../../enum/user.ts';
import { Establishment } from '../../../../models/establishment.tsx';
import { useUpdateEstablishmentMutation } from '../../../../services/establishments-api.services.ts';
import { useAppDispatch } from '../../../../hooks/hooks.tsx';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer.tsx';
import { useEffect } from 'react';
import { ToastType } from '../../../../enum/feedback.ts';
import { CommonDataValue } from '../../../../enum/common.enum.ts';

type props = {
  isOpened: boolean;
  establishment: Establishment;
  onClose: () => void;
};

const EditClinicModal = ({ isOpened, establishment, onClose }: props) => {
  const { t } = useTranslation(['profile']);
  const dispatch = useAppDispatch();

  const [patchEstablishment, { isSuccess: isPatchedEstablishment }] =
    useUpdateEstablishmentMutation();

  const submit = () => {
    const establishmentToUpdate: Establishment = {
      id: establishment.id,
      type: establishment.type,
      name: values.name as string,
      address: {
        address: values.address as string,
        zipCode: values.zipCode as string,
        city: values.city as string,
        country: values.country as Country
      }
    };
    patchEstablishment({ ...establishmentToUpdate });
  };

  useEffect(() => {
    if (isPatchedEstablishment) {
      dispatch(
        feedbackActions.setToast({
          message: t('modalClinic.toast.clinicUpdated'),
          type: ToastType.SUCCESS
        })
      );
      onClose();
    }
  }, [isPatchedEstablishment]);

  const handleCloseModal = () => {
    onClose();
  };

  const countries = [
    Object.values(Country).map((country) => {
      return {
        label: t(`countries.${country.toLowerCase()}`, { ns: 'common' }),
        value: country
      };
    })
  ];

  const initialValue = {
    name: establishment.name === CommonDataValue.EMPTY ? '' : establishment.name,
    address:
      establishment.address.address === CommonDataValue.EMPTY ? '' : establishment.address.address,
    zipCode:
      establishment.address.zipCode === CommonDataValue.EMPTY ? '' : establishment.address.zipCode,
    city: establishment.address.city === CommonDataValue.EMPTY ? '' : establishment.address.city,
    country: establishment.address.country ?? Country.FR
  };

  const { values, errors, handleChange, handleBlur, handleSelect, handleSubmit } = useForm(
    initialValue,
    submit
  );

  return (
    <Modal isOpened={isOpened} onClose={handleCloseModal} title={t('modalClinic.titleUpdate')}>
      <div className={styles['edit-clinic-modal']}>
        <form className={styles['edit-clinic-modal__form']} onSubmit={handleSubmit}>
          <TextField
            label={t('modalClinic.name')}
            id="name"
            name="name"
            value={values.name}
            type="text"
            helperText={errors.name && t('modalClinic.errors.name')}
            className={styles['edit-clinic-modal__form__textfield']}
            onBlur={handleBlur}
            onChange={handleChange}
            variant={errors.name ? 'danger' : 'default'}
            placeholder={t('modalClinic.placeholder.name')}
            radius="full"
            data-cy={'edit-clinic-modal-name'}
          />
          <TextField
            label={t('modalClinic.address')}
            id="address"
            name="address"
            value={values.address}
            type="text"
            helperText={errors.address && t('modalClinic.errors.address')}
            className={styles['edit-clinic-modal__form__textfield']}
            onBlur={handleBlur}
            onChange={handleChange}
            variant={errors.address ? 'danger' : 'default'}
            placeholder={t('modalClinic.placeholder.address')}
            radius="full"
            data-cy={'edit-clinic-modal-address'}
          />
          <TextField
            label={t('modalClinic.zipCode')}
            id="zipCode"
            name="zipCode"
            value={values.zipCode}
            type="text"
            helperText={errors.zipCode && t('modalClinic.errors.zipCode')}
            className={styles['edit-clinic-modal__form__input-zipcode']}
            onBlur={handleBlur}
            onChange={handleChange}
            variant={errors.zipCode ? 'danger' : 'default'}
            placeholder={t('modalClinic.placeholder.zipCode')}
            radius="full"
            data-cy={'edit-clinic-modal-zip-code'}
          />
          <TextField
            label={t('modalClinic.city')}
            id="city"
            name="city"
            value={values.city}
            type="text"
            helperText={errors.city && t('modalClinic.errors.city')}
            className={styles['edit-clinic-modal__form__textfield']}
            onBlur={handleBlur}
            onChange={handleChange}
            variant={errors.city ? 'danger' : 'default'}
            placeholder={t('modalClinic.placeholder.city')}
            radius="full"
            data-cy={'edit-clinic-modal-city'}
          />
          <Dropdown
            label={t('modalClinic.country')}
            data={countries}
            value={values?.country}
            radius="full"
            helperText={errors.country && t('modalClinic.errors.country')}
            variant={errors.country ? 'danger' : 'default'}
            placeholder={t('modalClinic.placeholder.country')}
            onChange={(newValue: string) => handleSelect(newValue, 'country')}
            onBlur={handleBlur}
            className={styles['edit-clinic-modal__form__dropdown']}
            data-cy={'edit-clinic-modal-countries-dropdown'}
          />
          <Button
            label={t('action.update', { ns: 'common' })}
            type="submit"
            variant={ColorPropsEnum.SUCCESS}
            iconLeft="fa-check"
            className={styles['edit-clinic-modal__form__submit']}
            data-cy={'edit-clinic-modal-submit'}
          />
        </form>
      </div>
    </Modal>
  );
};

export default EditClinicModal;
