import { MenuEntry } from '../../../../models/menu.tsx';
import i18next from 'i18next';
import { OrderForList } from '../../../../models/order.tsx';
import { WorkflowStepEnum } from '../../../../enum/workflow-step.ts';

export const downloadProductionFilesAction = <T extends OrderForList>(
  data: T,
  callback: (data: T) => void
): MenuEntry => {
  return {
    label: i18next.t('kebabMenuAction.downloadProductionFiles', { ns: 'treatments' }),
    type: 'button',
    disabled: data.currentStep !== WorkflowStepEnum.DELIVERED,
    onClick: () => {
      callback(data);
    }
  };
};

export const deleteOrderAction = <T extends OrderForList>(
  data: T,
  callback: (data: T) => void
): MenuEntry => {
  return {
    label: i18next.t('kebabMenuAction.deleteOrder', { ns: 'treatments' }),
    type: 'button',
    onClick: () => {
      callback(data);
    }
  };
};
