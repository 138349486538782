import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderFile, OrderItemLight, OrdersReducer } from '../../models/order';

const initialOrder: OrdersReducer = {
  files: [],
  items: [],
  error: '',
  currentItem: undefined,
  isLoadingFiles: false,
  isStreaming: false
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialOrder,
  reducers: {
    setFiles: (state, action: PayloadAction<OrderFile[]>) => {
      state.files = action.payload;
    },
    setOneFile: (state, action: PayloadAction<OrderFile>) => {
      const fileIndex = state.files.findIndex((file) => file.id === action.payload.id);
      if (fileIndex !== -1) {
        state.files[fileIndex] = action.payload;
      }
    },
    resetFiles: (state) => {
      state.files = initialOrder.files;
    },
    setCurrentItem: (state, action: PayloadAction<OrderItemLight | undefined>) => {
      state.currentItem = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setLoadingFiles: (state, action: PayloadAction<boolean>) => {
      state.isLoadingFiles = action.payload;
    },
    setIsStreaming: (state, action: PayloadAction<boolean>) => {
      state.isStreaming = action.payload;
    },
    resetOrder: () => initialOrder
  },
  extraReducers: (builder: ActionReducerMapBuilder<OrdersReducer>) => {
    builder.addCase('RESET_ALL', () => {
      return { ...initialOrder };
    });
  }
});

export const ordersActions = ordersSlice.actions;
