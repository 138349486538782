import { WizardPictoStep } from '../../../models/navigation.tsx';
import { WizardPictoStatusEnum } from '../../../enum/navigation.enum.ts';
import {
  faCubes as faCubesSolid,
  faDownload as faDownloadSolid,
  faPaperclip as faPaperSolid,
  faTooth as faToothSolid
} from '@fortawesome/pro-solid-svg-icons';
import { faCubes, faDownload, faPaperclip, faTooth } from '@fortawesome/pro-light-svg-icons';
import i18next from 'i18next';

const computeWizardStepStatus = (
  stepIndex: number,
  selectedStepIndex?: number
): WizardPictoStatusEnum => {
  if (selectedStepIndex && selectedStepIndex === stepIndex) {
    return WizardPictoStatusEnum.ACTIVE;
  }
  if (selectedStepIndex && selectedStepIndex > stepIndex) {
    return WizardPictoStatusEnum.VALID;
  }

  return stepIndex === 0 ? WizardPictoStatusEnum.ACTIVE : WizardPictoStatusEnum.DISABLED;
};

export const getWizardSteps = (selectedStepIndex?: number): WizardPictoStep[] => {
  return [
    {
      id: 'files',
      label: i18next.t('editOrder.wizard.patient-files', { ns: 'order' }),
      status: computeWizardStepStatus(0, selectedStepIndex),
      icon: selectedStepIndex === 0 ? faPaperSolid : faPaperclip
    },
    {
      label: i18next.t('editOrder.wizard.prosthesis', { ns: 'order' }),
      id: 'prosthesis',
      status: computeWizardStepStatus(1, selectedStepIndex),
      icon: selectedStepIndex === 1 ? faToothSolid : faTooth
    },
    {
      label: i18next.t('editOrder.wizard.design', { ns: 'order' }),
      id: 'design',
      status: computeWizardStepStatus(2, selectedStepIndex),
      icon: selectedStepIndex === 2 ? faCubes : faCubesSolid
    },
    {
      label: i18next.t('editOrder.wizard.export', { ns: 'order' }),
      id: 'export',
      status: computeWizardStepStatus(3, selectedStepIndex),
      icon: selectedStepIndex === 3 ? faDownloadSolid : faDownload
    }
  ];
};
