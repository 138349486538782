import { Text, Button, Modal } from '@platform-storybook/circlestorybook';
import { ColorPropsEnum } from '../../../../../enum/color.enum';
import { useTranslation } from 'react-i18next';
import { OrderForList } from '../../../../../models/order';
import { useDeleteOrder } from '../../../../../hooks/useDeleteOrder';
import styles from './delete-order-modal.module.scss';

type Props = {
  isOpened: boolean;
  order: OrderForList;
  onDeleteSuccess: () => void;
};
const DeleteOrderModal = ({ isOpened, order, onDeleteSuccess }: Props) => {
  const { t } = useTranslation(['treatments']);
  const { deleteOrderHandler, isLoading } = useDeleteOrder(onDeleteSuccess);

  return (
    <Modal
      data-cy="modal"
      isOpened={isOpened}
      title={t('deleteTreatmentModal.title')}
      onClose={onDeleteSuccess}>
      <>
        <Text
          textAlign="center-align"
          label={t('deleteTreatmentModal.text', { reference: order.patientReference })}
          color={ColorPropsEnum.GREY}
        />
        <Button
          data-cy="delete-order-modal-button"
          label={t('deleteTreatmentModal.button')}
          variant={ColorPropsEnum.ALERT}
          iconLeft="fa-trash"
          className={styles['delete-order-modal__button']}
          isLoading={isLoading}
          onClick={() => {
            deleteOrderHandler(order.orderNumber, order.patientReference);
          }}
        />
      </>
    </Modal>
  );
};

export default DeleteOrderModal;
