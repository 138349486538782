import { api } from './api';
import { Establishment } from '../models/establishment';

export const establishmentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createClinic: builder.mutation<Establishment, Establishment>({
      query: (payload: Establishment) => ({
        url: '/establishments',
        method: 'POST',
        body: payload
      })
    }),
    updateEstablishment: builder.mutation<Establishment, Establishment>({
      query: ({ id, ...patch }: Establishment) => ({
        url: `/establishments/${id}`,
        method: 'PATCH',
        body: patch
      }),
      invalidatesTags: ['UserInfo']
    })
  })
});

export const { useCreateClinicMutation, useUpdateEstablishmentMutation } = establishmentsApi;
