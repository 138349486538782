import '@inovua/reactdatagrid-enterprise/index.css';
import stylesDataGrid from './datagrid-feature.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import i18next from 'i18next';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { IColumn, TypeColumn } from '@inovua/reactdatagrid-community/types/TypeColumn';
import { CellProps, TypeDataGridProps } from '@inovua/reactdatagrid-community/types';
import { TypeDataSource } from '@inovua/reactdatagrid-community/types/TypeDataSource';

const rowExpandColumn: IColumn = {
  header: '',
  headerProps: {
    className: stylesDataGrid['datagrid-feature__header']
  }
};

const renderRowDetailsExpandIcon = () => {
  return <FontAwesomeIcon icon={faChevronDown} />;
};

const renderRowDetailsCollapsedIcon = () => {
  return <FontAwesomeIcon icon={faChevronRight} />;
};

const DatagridFeature = ({
  columns,
  style,
  dataSource,
  className,
  renderRowDetails,
  rowExpandHeight,
  expandedRows,
  multiRowExpand,
  collapsedRows,
  onExpandedRowsChange,
  rowClassName,
  onReady,
  defaultSortInfo,
  onSortInfoChange,
  filterValue,
  sortInfo,
  onFilterValueChange,
  rowHeight = 46,
  showZebraRows = true
}: Partial<TypeDataGridProps> & { columns: TypeColumn[]; dataSource: TypeDataSource }) => {
  // Adding custom styles to each column header & cell
  // @ts-expect-error Datagrid, u know the deal
  columns.forEach((element: TypeColumn & { cellProps: CellProps }) => {
    // Sorry but when we used a typed library that not have any coherence with their docs...
    (element.headerProps as object) = { className: 'datagrid__header' };
    (element.cellProps as object) = { className: 'datagrid__cell' };
    (element.textAlign as string) = 'center';
  });

  return (
    <ReactDataGrid
      licenseKey="AppName=multi_app,Company=CircleAnatoscopeIntuitiveDesign,ExpiryDate=2024-09-27T00:00:00.000Z,LicenseDeveloperCount=7,LicenseType=multi_app,Ref=CircleAnatoscopeIntuitiveDesignLicenseRef,Z=1092652003-1280136484-14595002217877925101092652003-220672582"
      columns={columns}
      // We have to add this, not just a className, because it isn't applied to the same element (this applies to a children while the className applies to the datagrid element
      style={{ ...style }}
      filterValue={filterValue}
      onFilterValueChange={onFilterValueChange}
      defaultLimit={25}
      showCellBorders={false}
      rowHeight={rowHeight}
      headerHeight={46}
      showColumnMenuTool={false}
      className={className}
      dataSource={dataSource}
      onReady={onReady}
      renderRowDetails={renderRowDetails}
      multiRowExpand={multiRowExpand}
      rowExpandHeight={rowExpandHeight}
      rowExpandColumn={rowExpandColumn}
      expandedRows={expandedRows}
      collapsedRows={collapsedRows}
      onExpandedRowsChange={onExpandedRowsChange}
      rowClassName={rowClassName}
      loadingText={i18next.t('loading', { ns: 'common' })}
      livePagination
      scrollThreshold={0.7}
      defaultSortInfo={defaultSortInfo}
      sortInfo={sortInfo}
      onSortInfoChange={onSortInfoChange}
      columnUserSelect={true}
      showZebraRows={showZebraRows}
      renderRowDetailsExpandIcon={renderRowDetailsExpandIcon}
      renderRowDetailsCollapsedIcon={renderRowDetailsCollapsedIcon}
      pagination></ReactDataGrid>
  );
};

export default DatagridFeature;
