import { Outlet } from 'react-router-dom';
import { Chips, Toast } from '@platform-storybook/circlestorybook';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks.tsx';
import { toastSelector } from '../../store/feedback/feedback.selector';
import { feedbackActions } from '../../store/feedback/feedback.reducer';
import { useTranslation } from 'react-i18next';
import styles from './public-app.module.scss';
import { ColorPropsEnum } from '../../enum/color.enum.ts';
import { viteMode } from '../../utils/utils.tsx';

const PublicApp = () => {
  const { t } = useTranslation(['common']);
  const toast = useAppSelector(toastSelector);
  const dispatch = useAppDispatch();
  const envLabel = viteMode();

  return (
    <div className={styles['public-app']}>
      {toast && (
        <Toast
          data-cy={`toast-${toast.type}`}
          message={'message' in toast ? toast.message : t('generic', { ns: 'error' })}
          onClose={() => {
            dispatch(feedbackActions.resetToast());
          }}
          autoHideDuration={8000}
          type={toast.type}
        />
      )}
      <main className={styles['public-app__main']}>
        <header className={styles['public-app__main__header']}>
          <img
            src="/logo/logo-circle-title-purple.svg"
            alt="Circle logo"
            className={styles['public-app__main__header__logo']}
          />
          {envLabel && (
            <Chips
              firstLabel={envLabel}
              color={ColorPropsEnum.WHITE}
              className={styles['public-app__main__header__env']}
            />
          )}
        </header>
        <Outlet />
      </main>
    </div>
  );
};
export default PublicApp;
