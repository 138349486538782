import { useAppSelector } from '../../../hooks/hooks.tsx';
import { connectedUserSelector } from '../../../store/auth/auth.selectors.tsx';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useGetConnectedUserQuery } from '../../../services/user.api.services.ts';
import { Establishment } from '../../../models/user.tsx';
import styles from './profile-page.module.scss';
import {
  Button,
  Divider,
  IconButton,
  Loader,
  Skeleton,
  Text
} from '@platform-storybook/circlestorybook';
import { Country, Language } from '../../../enum/user.ts';
import { ColorPropsEnum } from '../../../enum/color.enum.ts';
import AddEditMachineModal from './add-edit-machine-modal/AddEditMachineModal.tsx';
import DatagridFeature from '../../../features/datagrid/DatagridFeature.tsx';
import { MachineMaterialInfo } from '../../../models/machine.tsx';
import { extractMachineMaterialInfo } from '../../../utils/machine.utils.ts';
import { useDatagridColumns } from '../../../hooks/useDatagridColumns.tsx';
import EditClinicModal from './edit-clinic-modal/EditClinicModal.tsx';
import { CommonDataValue } from '../../../enum/common.enum.ts';
import { isClinic } from '../../../utils/utils.tsx';

const ProfilePage = () => {
  const connectedUser = useAppSelector(connectedUserSelector);
  const { t } = useTranslation(['profile']);
  const [isOpenAddMachineOpen, setIsOpenAddMachineOpen] = useState<boolean>(false);
  const [isOpenEditClinicOpen, setIsOpenEditClinicOpen] = useState<boolean>(false);

  const { data: user, isLoading, error } = useGetConnectedUserQuery();

  // machines config datagird columns
  const { colMachineName, colMaterial, colBrandReference } = useDatagridColumns();

  const handleClose = () => {
    setIsOpenAddMachineOpen(false);
  };

  const displayEstablishment = (establishment: Establishment, title: string) => {
    return (
      <>
        <section className={styles['profile-page__content__section']}>
          <header className={styles['profile-page__content__section__header']}>
            <Text
              data-cy="clinic-title"
              className={styles['profile-page__content__section__header__title']}
              size="s"
              type="title"
              label={title}></Text>
            {isClinic(establishment.type) && (
              <IconButton
                data-cy="edit-clinic-btn"
                iconSize="sm"
                faIconClass="fa-pen-to-square"
                onClick={() => setIsOpenEditClinicOpen(true)}
                radius="full"
              />
            )}
          </header>
          {isLoading ? (
            <>
              <Skeleton type="text" className="skeleton" />
              <Skeleton type="text" className="skeleton" />
              <Skeleton type="text" className="skeleton" />
              <Skeleton type="text" />
            </>
          ) : (
            !error &&
            (establishment.address?.address === CommonDataValue.EMPTY ? (
              <Text label={t('modalClinic.toComplete')} color={ColorPropsEnum.GREY} />
            ) : (
              <>
                <Text label={establishment.name}></Text>
                {establishment.address && (
                  <>
                    <Text label={establishment.address.address}></Text>
                    {establishment.address.additionalAddress && (
                      <Text label={establishment.address.additionalAddress}></Text>
                    )}
                    <Text
                      label={
                        establishment.address?.zipCode +
                        ' ' +
                        establishment.address?.city +
                        ' ' +
                        (establishment.address?.region ?? '')
                      }></Text>
                    <Text
                      label={
                        Object.values(Country).includes(establishment.address?.country as Country)
                          ? t(`countries.${establishment.address?.country?.toLowerCase()}`, {
                              ns: 'common'
                            })
                          : ''
                      }></Text>
                  </>
                )}
              </>
            ))
          )}
        </section>
        <section className={styles['profile-page__content__divider']}>
          <Divider color={ColorPropsEnum.GREY_300} />
        </section>
      </>
    );
  };

  const displayMachine = () => {
    const machines = user?.clinic?.manufacturingMachines;
    // Extract the machine name, material, and brandReference
    const machineMaterials: MachineMaterialInfo[] = extractMachineMaterialInfo(machines ?? []);
    return (
      <>
        <section className={styles['profile-page__content__section']}>
          <header className={styles['profile-page__content__section__header']}>
            <Text
              data-cy="machine-title"
              className={styles['profile-page__content__section__header__title']}
              label={t('detailDentist.myMachines')}
              size="s"
              type="title"
            />
          </header>

          {/* Conditional machines render */}
          {machines && machines.length > 0 ? (
            <DatagridFeature
              key="machines"
              dataSource={machineMaterials}
              columns={[colMachineName, colMaterial, colBrandReference]}
            />
          ) : (
            <div className={styles['profile-page__content__section__empty']}>
              <Text label={t('detailDentist.noMachinesAvailable')} color={ColorPropsEnum.GREY} />
            </div>
          )}

          {/* add machine button always visible when the clinic already loaded*/}
          {user?.clinic?.id && (
            <div>
              <Button
                className={styles['profile-page__content__section__add-machine-btn']}
                data-cy="add-machine"
                label={t('detailDentist.addMachineButton')}
                iconLeft="fa-plus"
                onClick={() => setIsOpenAddMachineOpen(true)}
              />
            </div>
          )}
        </section>

        {/* Divider */}
        <section className={styles['profile-page__content__divider']}>
          <Divider color={ColorPropsEnum.GREY_300} />
        </section>
      </>
    );
  };

  return connectedUser?.email ? (
    <>
      <div className={styles['profile-page__content']}>
        <section className={styles['profile-page__content__section']}>
          <header className={styles['profile-page__content__section__header__name']}>
            <div className={styles['profile-page__content__section__header__name-with-status']}>
              {user && (
                <>
                  <Text
                    data-cy="dentist-name"
                    className={styles['profile-page__content__section__header__title']}
                    label={`${user.firstName} ${user.lastName}`}
                    size="s"
                    type="title"></Text>
                </>
              )}
            </div>
          </header>
          <div className={styles['profile-page__content__section__main']}>
            <div>
              <div className={styles['profile-page__content__section__main__item']}>
                <Text
                  color={ColorPropsEnum.GREY}
                  label={t('detailDentist.identification.email')}></Text>
                {isLoading && <Skeleton type="text" />}
                {!isLoading && !error && <Text label={user?.email ?? ''}></Text>}
              </div>
              {!isLoading && user?.phoneNumber && (
                <div className={styles['profile-page__content__section__main__item']}>
                  <Text
                    color={ColorPropsEnum.GREY}
                    label={t('detailDentist.identification.phone')}></Text>
                  {!error && <Text label={user?.phoneNumber}></Text>}
                </div>
              )}
              <div className={styles['profile-page__content__section__main__item']}>
                <Text
                  color={ColorPropsEnum.GREY}
                  label={t('detailDentist.identification.role')}></Text>
                {isLoading && <Skeleton type="text" />}
                {!isLoading && !error && (
                  <Text
                    label={t(`user.roles.${user?.role.toLowerCase()}`, {
                      ns: 'common'
                    })}></Text>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className={styles['profile-page__content__divider']}>
          <Divider color={ColorPropsEnum.GREY_300} />
        </section>

        {/*********   Clinic section    ********/}
        {!isLoading &&
          user?.clinic &&
          Object.keys(user.clinic).length > 0 &&
          displayEstablishment(user.clinic, t('detailDentist.clinic'))}

        {/*********   Machines section    ********/}
        {!isLoading && displayMachine()}

        {/*********   Customization section    ********/}
        {!isLoading &&
          user?.designCenter &&
          Object.keys(user.designCenter).length > 0 &&
          displayEstablishment(user.designCenter, t('link.designCenter', { ns: 'common' }))}
        <section className={styles['profile-page__content__section']}>
          <header className={styles['profile-page__content__section__header']}>
            <Text
              className={styles['profile-page__content__section__header__title']}
              label={t('detailDentist.customization')}
              size="s"
              type="title"
            />
          </header>
          <div className={styles['profile-page__content__section__main__item']}>
            <Text color={ColorPropsEnum.GREY} label={t('detailDentist.language')}></Text>
            {isLoading && <Skeleton type="text" />}
            {!isLoading && !error && (
              <Text
                label={
                  user?.language && Object.values(Language).includes(user.language)
                    ? t(`language.${user?.language}`, { ns: 'common' })
                    : t('detailDentist.unknown')
                }></Text>
            )}
          </div>
        </section>
      </div>

      {/*  Add Machine modal */}
      {user?.clinic?.id && isOpenAddMachineOpen && (
        <AddEditMachineModal
          isOpened={isOpenAddMachineOpen}
          establishmentId={user.clinic.id}
          onClose={handleClose}
        />
      )}
      {/*  Edit clinic modal*/}
      {user?.clinic && isOpenEditClinicOpen && (
        <EditClinicModal
          isOpened={isOpenEditClinicOpen}
          establishment={user.clinic}
          onClose={() => setIsOpenEditClinicOpen(false)}
        />
      )}
    </>
  ) : (
    <Loader />
  );
};

export default ProfilePage;
