import { useAppDispatch } from '../../../../hooks/hooks.tsx';
import { useEffect, useState } from 'react';
import { useGetProductionZipFileMutation } from '../../../../services/manufacturing-orders-api.services.tsx';
import { WorkflowStepEnum } from '../../../../enum/workflow-step.ts';
import FileSaver from 'file-saver';
import { history } from '../../../../models/history.tsx';
import { useNavigate } from 'react-router-dom';
import styles from './export.module.scss';
import { Box, Button, Viewer, Text } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import JSZip from 'jszip';
import { OrderFile, OrderForUpdate } from '../../../../models/order.tsx';
import { isThumbprintExtension, mapFileToOrderFile } from '../../../../utils/file.utils.ts';
import { ColorPropsEnum } from '../../../../enum/color.enum.ts';
import { useDownloadFileFromStorageMutation } from '../../../../services/files-api.services.ts';
import { ordersActions } from '../../../../store/orders/orders.reducer.tsx';
import { mapActions } from '../../../../store/map/map.reducer.tsx';
import { useGetOneOrderQuery } from '../../../../services/orders-api.services.ts';

type Props = {
  orderNumber: string;
};
const ExportStep = ({ orderNumber }: Props) => {
  const { data: orderSelect } = useGetOneOrderQuery(orderNumber);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  history.navigate = useNavigate();
  const { t } = useTranslation(['order']);

  const [file3dToDisplay, setFile3dToDisplay] = useState<OrderFile>();
  const [zipData, setZipData] = useState<BlobPart | null>(null);
  const [skipOrderDataQuery, setSkipOrderDataQuery] = useState<boolean>(false);

  const [downloadFromStorage, { isLoading: isDownloadingFromStorage }] =
    useDownloadFileFromStorageMutation();
  const [getProductionZipFile, { isLoading: isDownloadingProductionFiles }] =
    useGetProductionZipFileMutation();
  // we query order => until we reach validation step => then allow query on manufacturing order and skip order
  const { data: orderData } = useGetOneOrderQuery(
    (orderSelect as OrderForUpdate).orderNumber as string,
    {
      pollingInterval: 2000,
      skip: skipOrderDataQuery
    }
  );

  useEffect(() => {
    if (orderData?.currentStep === WorkflowStepEnum.DELIVERED) {
      setSkipOrderDataQuery(true);
      handleLoadInViewer();
    }
  }, [orderData]);

  // handleLoadInViewer function
  const handleLoadInViewer = async () => {
    if (!orderData) return;
    const productionZipFileUrl = await getProductionZipFile(orderData.orderNumber);
    if (!productionZipFileUrl?.data) return;

    const zipFile = await downloadFromStorage({ url: productionZipFileUrl.data });
    if (zipFile?.data) {
      setZipData(zipFile?.data);
      loadProductionFilesInViewer(zipFile.data as Blob);
    }
  };

  // handle download when click on button download
  const handleDownload = async () => {
    if (!orderData) return;

    FileSaver.saveAs(zipData as Blob, `${orderData.patient.reference}.zip`);
    navigate('/treatments'); // Redirect after download
    // reset map and order
    dispatch(ordersActions.resetOrder());
    dispatch(mapActions.resetMap());
  };

  const getFileName = (fileNameWithDirectory: string): string | undefined => {
    const fileNameWithDirectorySplit = fileNameWithDirectory?.split('/');
    if (fileNameWithDirectorySplit?.length) {
      return fileNameWithDirectorySplit[fileNameWithDirectorySplit.length - 1];
    }
  };

  const getFileExtension = (fileName: string | undefined): string | undefined => {
    const fileNameSplit = fileName?.split('.');
    return fileNameSplit?.length ? fileNameSplit[fileNameSplit.length - 1] : undefined;
  };

  const loadProductionFilesInViewer = async (zipData: Blob) => {
    const zip = new JSZip();
    const zipContent = await zip.loadAsync(zipData.arrayBuffer());

    for (const [fileNameWithDirectory, zipObject] of Object.entries(zipContent.files)) {
      const fileName = getFileName(fileNameWithDirectory);
      const fileExtension = getFileExtension(fileName);
      if (fileName && fileExtension && isThumbprintExtension(fileExtension)) {
        const blob: Blob = await zipObject.async('blob');
        const file: File = new File([blob], fileName);
        setFile3dToDisplay(mapFileToOrderFile(file, false));
      }
    }
  };

  const isDownloading = (): boolean =>
    isDownloadingProductionFiles || isDownloadingFromStorage || !skipOrderDataQuery;

  return (
    <div className={styles['export']}>
      <Text
        label={t('editOrder.design.export.description')}
        color={ColorPropsEnum.GREY}
        className={styles['export__text-file']}
      />
      <Box color={ColorPropsEnum.GREY} className={styles['export__viewer']} padding="spacing-0">
        <Viewer
          noFileLabel={t('editOrder.patientFiles.noFile')}
          file3D={file3dToDisplay}
          isLoading={isDownloading()}
          className={styles['export__viewer__content']}
        />

        <div className={styles['export__title']}>
          <Button
            label={t(`editOrder.design.export.${isDownloading() ? 'title' : 'action'}`)}
            variant={ColorPropsEnum.SUCCESS}
            iconLeft="fa-check"
            data-cy={'download-files-button'}
            isDisabled={zipData === null}
            onClick={() => handleDownload()}
            isLoading={isDownloading()}
          />
        </div>
      </Box>
    </div>
  );
};

export default ExportStep;
