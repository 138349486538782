import { api } from './api';
import { NewUser, UserInfos } from '../models/user';
import { mapUser } from '../utils/user.utils';

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addUser: builder.mutation<UserInfos, NewUser>({
      query: (payload) => ({
        url: '/users',
        method: 'POST',
        body: payload
      })
    }),
    getConnectedUser: builder.query<UserInfos, void>({
      query: () => ({
        url: `/users/me`,
        method: 'GET'
      }),
      transformResponse: (response: UserInfos) => {
        return mapUser(response);
      },
      providesTags: ['UserInfo']
    }),
    resetPassword: builder.mutation<UserInfos, string>({
      query: (email) => ({
        url: `/users/${email}/reset-password`,
        method: 'POST'
      })
    })
  })
});

export const {
  useGetConnectedUserQuery,
  useLazyGetConnectedUserQuery,
  useResetPasswordMutation,
  useAddUserMutation
} = userApi;
