import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import {
  AuthenticationReducer,
  UserAuthentication,
  UserAuthenticationPayload
} from '../../models/user';
import { signIn, signOut } from '../../services/firebase.services';
import { ErrorCode } from '../../enum/error';
import { history } from '../../models/history.tsx';
import { AxiosError } from 'axios';
import { failureCallback, genericFeedbackError, getCustomClaims } from './auth.utils.tsx';
import { checkUserAccess } from '../../services/security-api.services.tsx';
import { api } from '../../services/api.ts';

const createInitialState = (): AuthenticationReducer => {
  return {
    isAuthenticated: undefined,
    connectedUser: undefined
  };
};

const createReducers = () => {
  const saveUserState = (
    state: AuthenticationReducer,
    action: PayloadAction<UserAuthenticationPayload>
  ) => {
    if (action.payload.firebaseUser !== 'null' && action.payload.isAuthorized) {
      const user = JSON.parse(action.payload.firebaseUser);
      state.connectedUser = {
        ...state.connectedUser,
        email: user.email,
        photoUrl: user.providerData ? user.providerData[0].photoUrl : undefined,
        role: action.payload.role
      };
      state.isAuthenticated = true;
    } else {
      state.isAuthenticated = false;
      state.connectedUser = undefined;
    }
  };

  return {
    saveUserState
  };
};

const createExtraActions = () => {
  const login = () => {
    return createAsyncThunk(
      `${name}/login`,
      async ({ username, password }: UserAuthentication, thunkAPI) => {
        const email = username.toLowerCase();
        try {
          await checkUserAccess(email);
          const userCredential = await signIn(email, password);
          return await getCustomClaims(email, userCredential);
        } catch (err) {
          if (err instanceof AxiosError) {
            return genericFeedbackError(thunkAPI);
          } else {
            return failureCallback(err as { code: ErrorCode }, thunkAPI);
          }
        }
      }
    );
  };
  const logout = () => {
    return createAsyncThunk(`${name}/logout`, async (_, { dispatch }) => {
      await signOut();

      dispatch(api.util.resetApiState());
    });
  };

  return { login: login(), logout: logout() };
};

const createExtraReducers = () => {
  return (builder: ActionReducerMapBuilder<AuthenticationReducer>) => {
    const login = () => {
      const { pending, fulfilled, rejected } = extraActions.login;
      builder
        .addCase(pending, (state) => {
          state.isAuthenticated = false;
        })
        .addCase(fulfilled, (state, action) => {
          // get return url from location state or default to home page
          state.isAuthenticated = action.payload?.isAuthenticated ?? false;
        })
        .addCase(rejected, (state) => {
          state.isAuthenticated = false;
        });
    };
    const logout = () => {
      const { fulfilled } = extraActions.logout;
      builder.addCase(fulfilled, (state) => {
        state.isAuthenticated = false;
        state.connectedUser = undefined;
        if (history.navigate) history.navigate('/login');
      });
    };
    login();
    logout();
  };
};

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraReducers = createExtraReducers();
export const authSlice = createSlice({ name, initialState, reducers, extraReducers });
const extraActions = createExtraActions();

export const authActions = { ...authSlice.actions, ...extraActions };
