import { useTranslation } from 'react-i18next';
import styles from './home-page.module.scss';
import { Button, Text, Skeleton } from '@platform-storybook/circlestorybook';
import { ColorPropsEnum } from '../../../enum/color.enum';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import moment from '../../../moment-config';
import { useGetConnectedUserQuery } from '../../../services/user.api.services';
import { Language } from '../../../enum/user';
import FilesSection from './files-section/FilesSection';
import CreateTreatmentModal from '../../../features/create-treatment-modal/CreateTreatmentModal';
import TreatmentsSection from './treatments-section/TreatmentsSection';

const HomePage = () => {
  const { t } = useTranslation(['home']);
  const [isCreateTreatmentModalOpened, setIsCreateTreatmentModalOpened] = useState<boolean>(false);

  const { data: connectedUser, isLoading: isUserLoading } = useGetConnectedUserQuery();

  useEffect(() => {
    if (connectedUser) {
      // Here we set the locale value for moment so date can be displayed in the correct language
      // We do it here since we couldn't make it work directly in PrivateApp.tsx
      const currentLanguage = connectedUser?.language === Language.fr_FR ? 'fr' : 'en';
      moment.locale(currentLanguage);
    }
  }, [connectedUser]);

  return (
    <>
      <section className={styles['home-page']}>
        <CreateTreatmentModal
          isOpened={isCreateTreatmentModalOpened}
          onClose={() => setIsCreateTreatmentModalOpened(false)}
        />
        <header className={styles['home-page__header']}>
          {isUserLoading ? (
            <Skeleton type="text" backgroundColor={ColorPropsEnum.GREY_100} />
          ) : (
            <Text
              size="xs"
              type="title"
              label={t('title').toUpperCase()}
              data-cy="home-title"
              color={ColorPropsEnum.GREY}
            />
          )}
        </header>
        <div className={styles['home-page__container']}>
          <div className={styles['home-page__container__header']}>
            <div className={styles['home-page__container__header__title']}>
              {isUserLoading ? (
                <>
                  <Skeleton
                    type="title"
                    height="48px"
                    width="300px"
                    backgroundColor={ColorPropsEnum.GREY_100}
                  />
                  <Skeleton
                    type="text"
                    height="16px"
                    width="100px"
                    backgroundColor={ColorPropsEnum.GREY_100}
                  />
                </>
              ) : (
                <>
                  <Text
                    size="m"
                    type="title"
                    label={t('welcome') + connectedUser?.lastName}
                    data-cy="home-dentist"
                    className={styles['home-page__container__header__title__dentist']}
                    color={ColorPropsEnum.PRIMARY}
                  />
                  <Text
                    size="xs"
                    type="title"
                    label={moment().format('dddd, DD MMMM YYYY')}
                    data-cy="home-date"
                    color={ColorPropsEnum.GREY}
                  />
                </>
              )}
            </div>
            {isUserLoading ? (
              <Skeleton
                type="square"
                width="150px"
                height="40px"
                backgroundColor={ColorPropsEnum.GREY_100}
              />
            ) : (
              <Button
                data-cy="home-createTreatment"
                label={t('button.label.fab')}
                variant={ColorPropsEnum.TERTIARY}
                iconLeft="fa-plus"
                onClick={() => {
                  setIsCreateTreatmentModalOpened(true);
                }}
              />
            )}
          </div>
          <div className={styles['home-page__container__content']}>
            <div className={styles['home-page__container__content__links']}>
              {isUserLoading ? (
                <>
                  <Skeleton
                    type="square"
                    width="100%"
                    height="45%"
                    backgroundColor={ColorPropsEnum.GREY_100}
                  />
                  <Skeleton
                    type="square"
                    width="100%"
                    height="15%"
                    backgroundColor={ColorPropsEnum.GREY_100}
                  />
                </>
              ) : (
                <>
                  <FilesSection />
                  <div
                    className={styles['home-page__container__content__links__event']}
                    data-cy="home-event">
                    <Text
                      size="s"
                      label={t('event.title').toUpperCase()}
                      data-cy="home-event-title"
                      className={styles['home-page__container__content__links__event__title']}
                    />
                    <div className={styles['home-page__container__content__links__event__content']}>
                      <Text
                        size="s"
                        label={t('event.description')}
                        data-cy="home-event-description"
                        color={ColorPropsEnum.WHITE}
                      />
                      <div
                        className={
                          styles['home-page__container__content__links__event__content__more']
                        }>
                        <FontAwesomeIcon icon={faChevronRight} size="sm" />
                        <Text
                          size="s"
                          label={t('event.more')}
                          data-cy="home-event-more"
                          color={ColorPropsEnum.WHITE}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className={styles['home-page__container__content__links__media']}>
                {isUserLoading ? (
                  <>
                    <Skeleton
                      type="text"
                      width="50%"
                      height="100%"
                      backgroundColor={ColorPropsEnum.GREY_100}
                    />
                    <Skeleton
                      type="text"
                      width="50%"
                      height="100%"
                      backgroundColor={ColorPropsEnum.GREY_100}
                    />
                  </>
                ) : (
                  <>
                    <div
                      className={styles['home-page__container__content__links__media__one']}
                      data-cy="home-one">
                      <img
                        src="https://storage.googleapis.com/public-circle-dental/images/home/arrow.png"
                        alt="arrow"
                        className={
                          styles['home-page__container__content__links__media__one__arrow']
                        }
                      />
                      <Text
                        size="s"
                        label={t('one.label').toUpperCase()}
                        data-cy="home-one-label"
                        color={ColorPropsEnum.WHITE}
                        className={
                          styles['home-page__container__content__links__media__one__label']
                        }
                      />
                      <Text
                        size="s"
                        type="title"
                        label={t('one.title').toUpperCase()}
                        data-cy="home-one-title"
                        color={ColorPropsEnum.WHITE}
                        bold
                        className={
                          styles['home-page__container__content__links__media__one__title']
                        }
                      />
                    </div>
                    <div
                      className={styles['home-page__container__content__links__media__tip']}
                      data-cy="home-tip">
                      <Text
                        size="s"
                        label={t('tip.label').toUpperCase()}
                        data-cy="home-tip-label"
                        color={ColorPropsEnum.WHITE}
                        className={
                          styles['home-page__container__content__links__media__tip__label']
                        }
                      />
                      <Text
                        size="s"
                        type="title"
                        label={t('tip.title').toUpperCase()}
                        data-cy="home-tip-title"
                        color={ColorPropsEnum.WHITE}
                        bold
                        className={
                          styles['home-page__container__content__links__media__tip__title']
                        }
                      />
                      <img
                        src="https://storage.googleapis.com/public-circle-dental/images/home/arrow.png"
                        alt="arrow"
                        className={
                          styles['home-page__container__content__links__media__tip__arrow']
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            {isUserLoading ? (
              <>
                <Skeleton
                  type="text"
                  width="40%"
                  height="100%"
                  backgroundColor={ColorPropsEnum.GREY_100}
                />
              </>
            ) : (
              <TreatmentsSection />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePage;
