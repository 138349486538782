import { Navigate, Outlet } from 'react-router-dom';
import { checkTokenValidity } from '../utils/utils.tsx';
import { useAppDispatch } from '../hooks/hooks.tsx';

interface ProtectedRouteObj {
  isAllowed: boolean | undefined;
  redirectPath: string;
  children: JSX.Element;
}

const ProtectedRoute = ({
  isAllowed,
  redirectPath = '/',
  children
}: ProtectedRouteObj): JSX.Element => {
  const dispatch = useAppDispatch();

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  checkTokenValidity(dispatch);
  return children || <Outlet />;
};

export default ProtectedRoute;
