import {
  IconButton,
  Text,
  TextField,
  Tooltip,
  TooltipContent
} from '@platform-storybook/circlestorybook';

import { ColorPropsEnum } from '../../../enum/color.enum.ts';
import { useState } from 'react';
import { Order } from '../../../models/order.tsx';
import useForm from '../../../hooks/useForm.tsx';
import styles from './patient-reference-form.module.scss';
import { usePatchOrderMutation } from '../../../services/orders-api.services.ts';

type Props = {
  order: Order;
};

const PatientReferenceForm = ({ order }: Props) => {
  // Redux
  const [patchOrder] = usePatchOrderMutation();

  // Local
  const [displayEditForm, setDisplayEditForm] = useState<boolean>(false);

  // Event handlers
  const onEditButtonClick = () => {
    setDisplayEditForm(true);
  };

  const onSubmit = () => {
    setDisplayEditForm(false);

    // Save in state
    const updatedOrder = {
      ...order,
      patient: { ...order?.patient, reference: values.patientReference }
    } as Order;

    // Patch the order
    patchOrder({
      orderNumber: updatedOrder.orderNumber,
      patient: { reference: updatedOrder.patient?.reference }
    });
  };

  // Form
  const { values, handleChange, handleSubmit } = useForm(
    { patientReference: order?.patient?.reference },
    onSubmit
  );

  return order?.patient?.reference ? (
    <div className={styles['patient-reference-form']}>
      {displayEditForm && (
        <form
          className={styles['patient-reference-form__form']}
          data-cy="patient-reference-form"
          onSubmit={handleSubmit}>
          <TextField
            id="patientReference"
            name="patientReference"
            value={values.patientReference}
            type="text"
            onChange={handleChange}
            data-cy="patient-reference-form-input"
            className={styles['patient-reference-form__form__input']}
            radius="full"
          />
          <IconButton
            faIconClass="check"
            radius="full"
            iconStyle="regular"
            color={ColorPropsEnum.SUCCESS}
            data-cy="patient-reference-form-submit"
            onClick={handleSubmit}
          />
        </form>
      )}
      {!displayEditForm && (
        <div data-cy="patient-reference" className={styles['patient-reference-form__content']}>
          <Tooltip>
            <TooltipContent>{values.patientReference}</TooltipContent>
            <Text
              data-cy="patient-reference-value"
              label={values.patientReference}
              size="l"
              className={styles['patient-reference-form__content__reference']}
            />
          </Tooltip>
          <IconButton
            faIconClass="pencil"
            radius="full"
            iconStyle="regular"
            color={ColorPropsEnum.PRIMARY}
            onClick={onEditButtonClick}
            data-cy="patient-reference-start-edit-button"
            iconSize="sm"
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default PatientReferenceForm;
