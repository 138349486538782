import { useState } from 'react';
import { useDeleteOrderMutation } from '../services/orders-api.services';
import { useDeleteManufacturingOrderMutation } from '../services/manufacturing-orders-api.services';
import { ErrorCode } from '../enum/error';
import { useAppDispatch } from './hooks';
import { feedbackActions } from '../store/feedback/feedback.reducer';
import { useTranslation } from 'react-i18next';
import { ToastType } from '../enum/feedback';
import { ordersActions } from '../store/orders/orders.reducer';
import { mapActions } from '../store/map/map.reducer';

export const useDeleteOrder = (onSuccess?: () => void) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteManufacturingOrder] = useDeleteManufacturingOrderMutation();
  const [deleteOrder] = useDeleteOrderMutation();
  const { t } = useTranslation(['common']);

  const dispatch = useAppDispatch();

  const handleSuccess = (patientReference: string) => {
    dispatch(ordersActions.resetOrder());
    dispatch(mapActions.resetMap());
    onSuccess?.();
    dispatch(
      feedbackActions.setToast({
        message: t('toast.deletedOrder', {
          reference: patientReference
        }),
        type: ToastType.SUCCESS
      })
    );
  };

  const deleteOrderHandler = async (orderNumber?: string, patientReference?: string) => {
    if (!orderNumber || !patientReference) return;

    setIsLoading(true);
    try {
      await deleteOrder({ orderNumber }).unwrap();
      await deleteManufacturingOrder({ orderNumber });
      handleSuccess(patientReference);
    } catch (error) {
      if (
        error instanceof Error &&
        'data' in error &&
        (error as { data?: { error?: string } }).data?.error === ErrorCode.MANU_ORDER_003
      ) {
        handleSuccess(patientReference);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { deleteOrderHandler, isLoading };
};
