/**
 * Generates a URL prefix based on the provided partner code.
 *
 * @param {string | undefined} partnerCode - The partner code to include in the URL prefix.
 * If it is undefined, an empty string is returned.
 * @returns {string} A string representing the URL prefix. If a partner code is provided,
 * it returns the format `/partners/{partnerCode}`. If no partner code is provided,
 * it returns an empty string.
 */
export const getUrlPrefix = (partnerCode: string | undefined): string =>
  partnerCode ? `/partners/${partnerCode}` : '';
