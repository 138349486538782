import { FullProduct } from '../../../../../models/product.tsx';
import { ToothShadeEnum } from '../../../../../enum/component.ts';
import { MapModeEnum } from '../../../../../enum/map.enum.ts';
import styles from './map-tools.module.scss';
import { MachineMaterialInfo } from '../../../../../models/machine.tsx';
import ProductsBubbles from './ProductsBubbles.tsx';
import ShadesBubbles from './ShadesBubbles.tsx';
import MachineMaterialBubbles from './MachineMaterialBubbles.tsx';

type Props = {
  currentProduct?: FullProduct;
  selectedShade?: ToothShadeEnum;
  toolMode?: MapModeEnum;
  currentTeethMachineMaterialInfo?: MachineMaterialInfo;
};

const MapTools = ({
  currentProduct,
  selectedShade,
  currentTeethMachineMaterialInfo,
  toolMode
}: Props) => {
  return (
    <div className={styles['map-tools']}>
      {toolMode === MapModeEnum.PROSTHESIS && <ProductsBubbles currentProduct={currentProduct} />}
      {toolMode === MapModeEnum.SHADES && (
        <ShadesBubbles selectedShade={selectedShade} currentProduct={currentProduct} />
      )}
      {toolMode === MapModeEnum.MATERIAL && (
        <MachineMaterialBubbles currentTeethMachineMaterialInfo={currentTeethMachineMaterialInfo} />
      )}
    </div>
  );
};
export default MapTools;
