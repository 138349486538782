import { Modebox } from '@platform-storybook/circlestorybook';
import { useEffect, useState } from 'react';
import { mapActions } from '../../../../../store/map/map.reducer';
import { MapModeEnum } from '../../../../../enum/map.enum';
import { selectedTeethSelector, teethShadesSelector } from '../../../../../store/map/map.selectors';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import { useTranslation } from 'react-i18next';

const ModeBoxPlan = () => {
  const { t } = useTranslation(['order']);
  const selectedTeeth = useAppSelector(selectedTeethSelector);
  const teethShades = useAppSelector(teethShadesSelector);
  const selectedMachineMaterialInfo = selectedTeeth[0]?.machineMaterialInfo;
  const dispatch = useAppDispatch();

  const [modeboxData, setModeboxData] = useState([
    { label: t('editOrder.plan.modeBox.prosthesis'), isValid: false, isDisabled: false },
    { label: t('editOrder.plan.modeBox.configuration'), isValid: false, isDisabled: true },
    { label: t('editOrder.plan.modeBox.teethShade'), isValid: false, isDisabled: true }
  ]);
  const [activeIdModbox, setActiveIdModebox] = useState(0);

  const prosthesisStepIsValid: boolean = selectedTeeth.length > 0;
  const teethShadesStepIsValid: boolean = Object.keys(teethShades).length > 0;
  const materialStepIsValid: boolean = selectedMachineMaterialInfo !== undefined;

  useEffect(() => {
    if (activeIdModbox === 0) {
      dispatch(
        mapActions.setMapContext({
          mode: MapModeEnum.PROSTHESIS
        })
      );
    }
  }, []);

  useEffect(() => {
    setModeboxData([
      {
        label: t('editOrder.plan.modeBox.prosthesis'),
        isValid: prosthesisStepIsValid,
        isDisabled: false
      },
      {
        label: t('editOrder.plan.modeBox.configuration'),
        isValid: materialStepIsValid,
        isDisabled: !prosthesisStepIsValid
      },
      {
        label: t('editOrder.plan.modeBox.teethShade'),
        isValid: teethShadesStepIsValid,
        isDisabled: !prosthesisStepIsValid || !materialStepIsValid
      }
    ]);
  }, [prosthesisStepIsValid, teethShadesStepIsValid, materialStepIsValid]);

  const handleClickModebox = (id: number) => {
    if (id === 0) {
      dispatch(mapActions.setMapContext({ mode: MapModeEnum.PROSTHESIS }));
    } else if (id === 1) {
      dispatch(mapActions.setMapContext({ mode: MapModeEnum.MATERIAL }));
    } else if (id === 2) {
      dispatch(mapActions.setMapContext({ mode: MapModeEnum.SHADES }));
    }
    setActiveIdModebox(id);
  };

  return (
    <Modebox
      data-cy="modebox"
      activeIndex={activeIdModbox}
      data={modeboxData}
      onClick={(id: number) => handleClickModebox(id)}
    />
  );
};

export default ModeBoxPlan;
