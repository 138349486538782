export enum MachineCamEnum {
  MILLBOX = 'MILLBOX'
}

export enum MinimalThicknessEnum {
  MIN = 0.1,
  MAX = 2,
  STEP = 0.05
}

export enum ToolDiameterEnum {
  MIN = 0.3,
  MAX = 2,
  STEP = 0.05
}

export enum ReinforcementThicknessEnum {
  MIN = 0,
  MAX = 0.3,
  STEP = 0.01
}

export enum AxialCementGapEnum {
  MIN = 0,
  MAX = 0.2,
  STEP = 0.01
}

export enum OcclusalCementGapEnum {
  MIN = 0,
  MAX = 0.3,
  STEP = 0.01
}

export enum RetentionGapEnum {
  MIN = 0,
  MAX = 0.1,
  STEP = 0.01
}

export enum RetentionZoneWidthEnum {
  MIN = 0,
  MAX = 1,
  STEP = 0.01
}

export enum OcclusalDistanceEnum {
  MIN = -0.5,
  MAX = 0.5,
  STEP = 0.05
}

export enum ProximalDistanceEnum {
  MIN = -0.5,
  MAX = 0.5,
  STEP = 0.01
}
