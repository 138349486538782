import styles from './create-treatment-modal.module.scss';
import { Button, Text, TextField, Modal } from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import TreatmentTypeRadioList from './treatment-type-radio-list/TreatmentTypeRadioList';
import moment from 'moment';
import { ColorPropsEnum } from '../../enum/color.enum';
import useForm from '../../hooks/useForm';
import { useAppDispatch } from '../../hooks/hooks';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import { useCreateOrderMutation } from '../../services/orders-api.services';
import { useGetConnectedUserQuery } from '../../services/user.api.services';
import { OrderForCreation } from '../../models/order';
import { ordersActions } from '../../store/orders/orders.reducer';
import { Tag } from '../../enum/order';
import { mapActions } from '../../store/map/map.reducer';

type props = {
  isOpened: boolean;
  onClose: () => void;
};

const CreateTreatmentModal = ({ isOpened, onClose }: props) => {
  const { t } = useTranslation(['treatments']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [
    createOrder,
    { data: createdOrder, isLoading: isCreateOrderLoading, isSuccess: isSuccessCreateOrder }
  ] = useCreateOrderMutation();
  const { data: connectedUser } = useGetConnectedUserQuery();

  const submit = () => {
    onSubmit(values.reference as string);
  };

  const onSubmit = async (reference: string) => {
    const orderToCreate: OrderForCreation = {
      dentistName: `${connectedUser?.firstName} ${connectedUser?.lastName}`,
      dentistEmail: `${connectedUser?.email}`,
      clinicName: `${connectedUser?.clinic?.name}`,
      clinicId: +`${connectedUser?.clinic?.id}`,
      toManufacture: true,
      patient: { reference },
      tags: [Tag.CHAIRSIDE],
      labId: +`${connectedUser?.laboratory?.id}`,
      labName: `${connectedUser?.laboratory?.name}`,
      expectedDate: new Date(),
      instructions: ''
    };
    await createOrder(orderToCreate);
  };

  useEffect(() => {
    if (isSuccessCreateOrder) {
      dispatch(ordersActions.resetOrder());
      dispatch(mapActions.resetMap());
      navigate(`/order/${createdOrder.orderNumber}/edit`);
    }
  }, [isSuccessCreateOrder]);

  const { values, errors, handleSubmit, handleSelect, handleChange } = useForm(
    {
      reference: 'PATIENT_' + moment().format('YYMMDD'),
      treatmentType: 'one'
    },
    submit
  );

  return (
    <Modal isOpened={isOpened} onClose={onClose} title={t('createTreatmentModal.title')}>
      <section className={styles['create-treatment-modal__content']}>
        <Text
          size="s"
          textAlign="center-align"
          label={t('createTreatmentModal.helper')}
          color={ColorPropsEnum.GREY}
          className={styles['create-treatment-modal__helper']}
        />
        <form onSubmit={handleSubmit} className={styles['create-treatment-modal__input__form']}>
          <TreatmentTypeRadioList
            options={[
              {
                value: 'one',
                logoClass: 'logo-one',
                label: t('createTreatmentModal.options.one.description')
              },
              {
                value: 'inlab',
                isDisabled: true,
                logoClass: 'logo-inlab',
                label: t('createTreatmentModal.options.inlab.description')
              }
            ]}
            selectedValue={values.treatmentType as string}
            onClick={(newValue: string) => {
              handleSelect(newValue, 'treatmentType');
            }}
          />

          <TextField
            label={t('createTreatmentModal.reference.title')}
            labelColor={ColorPropsEnum.GREY}
            id="reference"
            name="reference"
            value={values.reference}
            type="text"
            helperText={
              errors.reference
                ? t('createTreatmentModal.reference.error')
                : t('createTreatmentModal.reference.helper')
            }
            className={styles['create-treatment-modal__input__form__textfield']}
            onChange={handleChange}
            variant={errors.reference ? 'danger' : 'default'}
            radius="full"
            isFocused
            data-cy={'create-treatment-modal-reference'}
          />
          <Button
            label={t('createTreatmentModal.submit')}
            onClick={handleSubmit}
            variant={ColorPropsEnum.SUCCESS}
            iconLeft="fa-check"
            isLoading={isCreateOrderLoading}
            className={styles['create-treatment-modal__input__form__submit']}
            data-cy={'create-treatment-modal-submit'}
          />
        </form>
      </section>
    </Modal>
  );
};

export default CreateTreatmentModal;
