import { Machine, MachineMaterialInfo } from '../models/machine.tsx';
import { MaterialEnum } from '../enum/component.ts';

export const extractMachineMaterialInfo = (machines: Machine[]): MachineMaterialInfo[] => {
  return machines
    .filter((machine) => Array.isArray(machine.params) && machine.params.length > 0) // Ensure params exist
    .flatMap((machine) =>
      machine.params!.map((param) => ({
        id: param.id,
        machineName: machine.name,
        material: param.material as MaterialEnum,
        brandReference: param.brandReference
      }))
    );
};
