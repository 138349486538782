import moment from 'moment';
import { ColorPropsEnum } from '../enum/color.enum';
import { Order, OrderForList } from '../models/order';

export const isOrderChairsideCompliant = (order: Order | OrderForList): boolean | undefined => {
  // validation to one design if all products in the order are chairside compliant
  return order?.items?.length === order?.items?.filter((item) => item.product.isChairSide)?.length;
};

export const getBackgroundColor = (order: OrderForList) => {
  return isOrderChairsideCompliant(order) ? ColorPropsEnum.GREY_100 : ColorPropsEnum.PURPLE_MEDIUM;
};

export const getDefaultPatientReference = () => {
  return 'PATIENT_' + moment().format('YYMMDD');
};
