import { createSelector } from '@reduxjs/toolkit';
import { OrdersReducer } from '../../models/order';

interface ReducerState {
  ordersState: OrdersReducer;
}

export const currentItemSelector = (state: ReducerState) => {
  return state?.ordersState?.currentItem;
};

export const orderFilesSelector = (state: ReducerState) => {
  return state?.ordersState?.files;
};

export const orderErrorSelector = (state: ReducerState) => {
  return state?.ordersState?.error;
};

export const isLoadingFilesSelector = (state: ReducerState) => {
  return state.ordersState.isLoadingFiles;
};

export const isOrderStreamingSelector = (state: ReducerState) => {
  return state?.ordersState?.isStreaming;
};

export const notCompatibleToothProductsSelector = createSelector(
  [currentItemSelector],
  (currentItem) => {
    const notCompatibleToothProducts: number[] =
      currentItem?.product?.productCompatibilities
        ?.filter((productCompatibility) => !productCompatibility.toothCompatibility)
        ?.map((productCompatibility) =>
          productCompatibility.compatibleProduct.id === currentItem?.product?.id
            ? productCompatibility.product.id
            : productCompatibility.compatibleProduct.id
        ) || [];
    return notCompatibleToothProducts;
  }
);

export const notCompatibleArchProductSelector = createSelector(
  [currentItemSelector],
  (currentItem) => {
    const notCompatibleArchProduct: number[] =
      currentItem?.product?.productCompatibilities
        ?.filter((productCompatibility) => !productCompatibility.archCompatibility)
        ?.map((productCompatibility) =>
          productCompatibility.compatibleProduct.id === currentItem?.product?.id
            ? productCompatibility.product.id
            : productCompatibility.compatibleProduct.id
        ) || [];
    return notCompatibleArchProduct;
  }
);
