import styles from './add-edit-machine-modal.module.scss';
import {
  Button,
  Dropdown,
  Text,
  TextField,
  Divider,
  NumberInput,
  Modal,
  Checkbox
} from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import useForm from '../../../../hooks/useForm.tsx';
import {
  MinimalThicknessEnum,
  MachineCamEnum,
  ToolDiameterEnum,
  ReinforcementThicknessEnum,
  ProximalDistanceEnum,
  OcclusalDistanceEnum,
  RetentionGapEnum,
  OcclusalCementGapEnum,
  RetentionZoneWidthEnum,
  AxialCementGapEnum
} from '../../../../enum/machine.enum.ts';
import {
  ComponentType,
  ManufacturingProcessEnum,
  MaterialEnum
} from '../../../../enum/component.ts';
import { StringObject, UnknownObject } from '../../../../models/common.tsx';
import { useGetCommonTypesQuery } from '../../../../services/common-types-api.services.ts';
import {
  useCreateMachineMutation,
  useCreateMachineParamsMutation
} from '../../../../services/machines-api.services.ts';
import { MachineForCreation, MachineParamsForCreation } from '../../../../models/machine.tsx';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../../hooks/hooks.tsx';
import { feedbackActions } from '../../../../store/feedback/feedback.reducer.tsx';
import { ToastType } from '../../../../enum/feedback.ts';
import { getMessageError } from '../../../../utils/utils.tsx';

type props = {
  isOpened: boolean;
  establishmentId: number;
  onClose: () => void;
};

const AddEditMachineModal = ({ isOpened, establishmentId, onClose }: props) => {
  const { t } = useTranslation(['profile']);
  const dispatch = useAppDispatch();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [isAllCreated, setIsAllCreated] = useState<boolean>(false);
  const [minimalKey, setMinimalKey] = useState<number>(0);

  const [
    createMachine,
    {
      isSuccess: isMachineCreated,
      error: isMachineCreateError,
      data: EstablishmentAfterMachineCreate
    }
  ] = useCreateMachineMutation();
  const [createMachineParams, { isSuccess: isMachineParamsCreated, error: isMachineParamsError }] =
    useCreateMachineParamsMutation();
  const { data: commonTypes, isLoading: areCommonTypesLoading } = useGetCommonTypesQuery();

  // Render Number Input function
  type NumberInputProps = {
    name: string;
    label: string;
    enumValues: { MIN: number; MAX: number; STEP: number };
    className: string;
  };

  const renderNumberInput = ({ name, label, enumValues, className }: NumberInputProps) => (
    <NumberInput
      label={t(label)}
      className={className}
      id={name}
      name={name}
      value={values[name]}
      unitLabel="mm"
      min={enumValues.MIN}
      max={enumValues.MAX}
      step={enumValues.STEP}
      helperText={errors[name] && t('modalMachine.errors.configurationEmpty')}
      variant={errors[name] ? 'danger' : 'default'}
      placeholder={0}
      onBlur={handleBlur}
      onChange={handleChange}
      data-cy={`add-edit-machine-modal-${name}`}
    />
  );

  // Submit to create machine
  const submit = async () => {
    setIsCreating(true);
    setIsAllCreated(false);

    const newMachine: MachineForCreation = {
      name: values.name as string,
      ipAddress: values.ipAddress as string,
      port: values.port as number,
      manufacturingProcess: values.manufacturingProcess as ManufacturingProcessEnum,
      computerAidedManufacturing: values.computerAidedManufacturing as MachineCamEnum
    };

    await createMachine({ establishmentId, machine: newMachine });
  };

  // submitCreateParams function to send the request to create the params for the machine. And for each material in the machine
  const submitCreateParams = async (machineId: number) => {
    const materials = values.materials as {
      material: string;
      thickness: number | string;
      brandReference: string;
    }[];

    // Iterate over each material and make a separate API call for each
    for (const material of materials) {
      const newMachinePrams: MachineParamsForCreation = {
        isFirstStepModeling: values.isFirstStepModeling as boolean,
        material: material.material as MaterialEnum, // Get the material name
        brandReference: material.brandReference,
        minimalCrownThickness: parseFloat(material.thickness as string), // Use thickness as minimalCrownThickness
        axialCementGap: parseFloat(values.axialCementGap as string),
        occlusalCementGap: parseFloat(values.occlusalCementGap as string),
        retentionGap: parseFloat(values.retentionGap as string),
        retentionZoneWidth: parseFloat(values.retentionZoneWidth as string),
        toolDiameter: parseFloat(values.toolDiameter as string),
        reinforcementThickness: parseFloat(values.reinforcementThickness as string),
        occlusalDistance: parseFloat(values.occlusalDistance as string),
        proximalDistance: parseFloat(values.proximalDistance as string)
      };

      // Make the call to create machine params for the current material
      await createMachineParams({
        establishmentId,
        machineId,
        machineParams: newMachinePrams
      })
        .unwrap()
        .then(() => setIsAllCreated(true))
        .catch((error) => {
          dispatch(
            feedbackActions.setToast({
              message: getMessageError(error),
              type: ToastType.DANGER
            })
          );
        });
    }
  };

  // useEffect after machine creation to create params
  useEffect(() => {
    if (isMachineCreated && EstablishmentAfterMachineCreate?.manufacturingMachines?.length) {
      const lastMachine =
        EstablishmentAfterMachineCreate.manufacturingMachines[
          EstablishmentAfterMachineCreate.manufacturingMachines.length - 1
        ];
      submitCreateParams(lastMachine.id);
    }
  }, [isMachineCreated, EstablishmentAfterMachineCreate]);

  // useEffect after machine and params creations or if we have an error during creation. put the isCreation to false
  useEffect(() => {
    if (
      (isMachineCreated && isMachineParamsCreated) ||
      isMachineCreateError ||
      isMachineParamsError
    ) {
      setIsCreating(false);
    }
  }, [isMachineCreated, isMachineParamsCreated, isMachineCreateError, isMachineParamsError]);

  // useEffect after creation success to print a toast message.
  useEffect(() => {
    if (isAllCreated && EstablishmentAfterMachineCreate?.manufacturingMachines?.length) {
      dispatch(
        feedbackActions.setToast({
          message: t('modalMachine.toast.machineCreated', {
            machineName:
              EstablishmentAfterMachineCreate?.manufacturingMachines[
                EstablishmentAfterMachineCreate?.manufacturingMachines.length - 1
              ].name
          }),
          type: ToastType.SUCCESS
        })
      );
      // close modal after creation.
      handleCloseModal();
    }
  }, [isAllCreated]);

  const handleCloseModal = () => {
    setIsAllCreated(false);
    // reset initial values after modal close
    resetValues();
    // call onClose function
    onClose();
  };

  // This function handle the material add for a machine.
  // while we set the material value and the thickness related to it.
  // Error handling when we add a material, and checking if the material already exists.
  const handleAddMaterialList = () => {
    if (values.material && values.minimalCrownThickness && values.brandReference) {
      const materialValue = values.material as string;
      const thicknessValue = values.minimalCrownThickness as number;
      const brandReference = values.brandReference as string;
      const materials = values.materials as {
        material: string;
        thickness: string;
        brandReference: string;
      }[];

      // Check if the material already exists (ignoring thickness)
      const materialExists = materials.some((item) => item.material === materialValue);

      if (materialExists) {
        setErrors((errors: StringObject) => ({
          ...errors,
          ['materials']: 'alreadyExists'
        }));
      } else {
        // Check min max of the thickness
        const checkMinMaxThicknessNotBetween =
          thicknessValue < MinimalThicknessEnum.MIN || thicknessValue > MinimalThicknessEnum.MAX;
        if (checkMinMaxThicknessNotBetween) {
          setErrors((errors: StringObject) => ({
            ...errors,
            ['minimalCrownThickness']: 'notBetween'
          }));
        } else {
          // Add the new material and thickness to the materials list
          const updatedMaterials = [
            ...materials,
            { material: materialValue, thickness: thicknessValue, brandReference: brandReference }
          ];
          setValues((values: UnknownObject) => ({
            ...values,
            ['materials']: updatedMaterials
          }));
          // reset material dropdown
          resetValue('material');
          resetValue('minimalCrownThickness');
          // we cannot reset the value of numberInput without the key change.
          setMinimalKey((prevKey) => prevKey + 1);

          setErrors((errors: StringObject) => ({
            ...errors,
            ['materials']: undefined
          }));
        }
      }
    } else {
      if (!values.material) {
        setErrors((errors: StringObject) => ({
          ...errors,
          ['material']: 'empty'
        }));
      }
      if (!values.minimalCrownThickness) {
        setErrors((errors: StringObject) => ({
          ...errors,
          ['minimalCrownThickness']: 'empty'
        }));
      }
      if (!values.brandReference) {
        setErrors((errors: StringObject) => ({
          ...errors,
          ['brandReference']: 'empty'
        }));
      }
    }
  };

  const initialValue = {
    name: '',
    isFirstStepModeling: false,
    ipAddress: '192.168.0.0',
    port: 336,
    manufacturingProcess: ManufacturingProcessEnum.MILLING,
    computerAidedManufacturing: MachineCamEnum.MILLBOX,
    material: undefined,
    brandReference: '',
    materials: [],
    // configurations
    minimalCrownThickness: 0.45,
    axialCementGap: 0.08,
    occlusalCementGap: 0.08,
    retentionGap: 0.02,
    retentionZoneWidth: 1,
    toolDiameter: 0.6,
    reinforcementThickness: 0.1,
    occlusalDistance: 0,
    proximalDistance: 0
  };

  const validateAllFieldsRequired = (): StringObject => {
    const newErrors: StringObject = {};
    Object.keys(values).forEach((key) => {
      // Skip the 'material' and 'minimalCrownThickness' keys
      if (
        key !== 'material' &&
        key !== 'minimalCrownThickness' &&
        key !== 'isFirstStepModeling' &&
        key !== 'brandReference' &&
        !values[key] &&
        values[key] !== 0
      ) {
        newErrors[key] = 'empty';
      }
      // Check if the 'materials' array is empty
      if (key === 'materials' && Array.isArray(values[key]) && values[key].length === 0) {
        newErrors[key] = 'empty';
      }
    });
    return newErrors;
  };

  const {
    values,
    errors,
    handleSubmit,
    handleSelect,
    handleChange,
    handleCheck,
    handleBlur,
    resetValues,
    resetValue,
    setErrors,
    setValues
  } = useForm(initialValue, submit, validateAllFieldsRequired);

  // we filter the material by componentType === 'TOOTH' and then remove the doubling. And disable the added materials
  const materialsDropdownData = useMemo(() => {
    return commonTypes?.materials
      .filter((material) => material.componentType === ComponentType.TOOTH)
      .reduce<{ label: string; value: MaterialEnum; isDisabled?: boolean }[]>(
        (uniqueMaterials, material) => {
          if (!uniqueMaterials.some((item) => item.value === material.code)) {
            uniqueMaterials.push({
              label: t(`material.${material.code}`, { ns: 'component' }),
              value: material.code as MaterialEnum,
              isDisabled:
                Array.isArray(values?.materials) &&
                values.materials.some((m) => m.material === material.code) // Disable if it already exists
            });
          }
          return uniqueMaterials;
        },
        []
      );
  }, [commonTypes, values.materials]); //  Update just when materials list changes.

  return (
    <Modal isOpened={isOpened} onClose={handleCloseModal} title={t('modalMachine.titleCreate')}>
      <form className={styles['add-edit-machine-modal__input__form']} onSubmit={handleSubmit}>
        <div className={styles['add-edit-machine-modal']}>
          <div className={styles['add-edit-machine-modal__input']}>
            <div className={styles['add-edit-machine-modal__input__form__first-section']}>
              <TextField
                label={t('modalMachine.name')}
                id="name"
                name="name"
                value={values.name}
                type="text"
                helperText={errors.name && t('modalMachine.errors.name')}
                className={styles['add-edit-machine-modal__input__form__first-section__textfield']}
                onBlur={handleBlur}
                onChange={handleChange}
                variant={errors.name ? 'danger' : 'default'}
                placeholder={t('modalMachine.placeholder.name')}
                radius="full"
                isFocused
                data-cy={'add-edit-machine-modal-name'}
              />
            </div>

            <Divider className={styles['add-edit-machine-modal__divider']} />
            <Text
              size="m"
              textAlign="center-align"
              label={t('modalMachine.machineParamTitle')}
              color={ColorPropsEnum.GREY}
              bold
              className={styles['add-edit-machine-modal__helper']}
            />
            <div className={styles['add-edit-machine-modal__input__form__configuration-machine']}>
              {/* Machine Configuration */}
              {renderNumberInput({
                name: 'toolDiameter',
                label: 'modalMachine.toolDiameter',
                enumValues: ToolDiameterEnum,
                className:
                  styles['add-edit-machine-modal__input__form__configuration-machine__input-number']
              })}
              {renderNumberInput({
                name: 'reinforcementThickness',
                label: 'modalMachine.reinforcementThickness',
                enumValues: ReinforcementThicknessEnum,
                className:
                  styles['add-edit-machine-modal__input__form__configuration-machine__input-number']
              })}
            </div>
            <Divider className={styles['add-edit-machine-modal__divider']} />
            <Text
              size="m"
              textAlign="center-align"
              label={t('modalMachine.materialParamTitle')}
              color={ColorPropsEnum.GREY}
              bold
              className={styles['add-edit-machine-modal__helper']}
            />
            <div className={styles['add-edit-machine-modal__input__form__material-section']}>
              <div
                className={
                  styles['add-edit-machine-modal__input__form__material-section__material-and-ref']
                }>
                <Dropdown
                  label={t('modalMachine.material')}
                  data={areCommonTypesLoading ? [] : [materialsDropdownData]}
                  value={values?.material}
                  isLoading={areCommonTypesLoading}
                  radius="full"
                  helperText={
                    errors.material
                      ? t('modalMachine.errors.material')
                      : errors.materials === 'alreadyExists'
                        ? t('modalMachine.errors.materialExist')
                        : ''
                  }
                  variant={
                    errors.material || errors.materials === 'alreadyExists' ? 'danger' : 'default'
                  }
                  placeholder={t('modalMachine.placeholder.material')}
                  onChange={(newValue: string) => {
                    handleSelect(newValue, 'material');
                    setErrors({ ...errors, ['materials']: undefined, ['material']: undefined });
                  }}
                  onBlur={handleBlur}
                  className={
                    styles[
                      'add-edit-machine-modal__input__form__material-section__material-and-ref__dropdown'
                    ]
                  }
                  data-cy="materials-dropdown"
                />
                <TextField
                  label={t('modalMachine.brandReference')}
                  id="brandReference"
                  name="brandReference"
                  value={values.brandReference}
                  type="text"
                  helperText={errors.brandReference && t('modalMachine.errors.brandReference')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant={errors.brandReference ? 'danger' : 'default'}
                  placeholder={t('modalMachine.placeholder.brandReference')}
                  radius="full"
                  data-cy="add-edit-machine-modal-brandReference"
                />
              </div>

              <div
                className={
                  styles['add-edit-machine-modal__input__form__material-section__number-section']
                }>
                <NumberInput
                  key={minimalKey}
                  label={t('modalMachine.minimalCrownThickness')}
                  className={
                    styles['add-edit-machine-modal__input__form__material-section__number']
                  }
                  id="minimalCrownThickness"
                  name="minimalCrownThickness"
                  value={values.minimalCrownThickness}
                  unitLabel="mm"
                  min={MinimalThicknessEnum.MIN}
                  max={MinimalThicknessEnum.MAX}
                  step={MinimalThicknessEnum.STEP}
                  helperText={
                    errors.minimalCrownThickness === 'empty'
                      ? t('modalMachine.errors.configurationEmpty')
                      : errors.minimalCrownThickness === 'notBetween'
                        ? t('modalMachine.errors.configurationNotBetween', {
                            min: MinimalThicknessEnum.MIN,
                            max: MinimalThicknessEnum.MAX
                          })
                        : ''
                  }
                  variant={errors.minimalCrownThickness ? 'danger' : 'default'}
                  placeholder={0}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  data-cy={'add-edit-machine-modal-minimalCrownThickness'}
                />

                <div
                  className={
                    styles[
                      'add-edit-machine-modal__input__form__material-section__add-material-btn'
                    ]
                  }>
                  <Button
                    className={styles['profile-page__content__section__add-machine-btn']}
                    data-cy="add-new-material-config-button"
                    label={t('modalMachine.addMaterialBtn')}
                    iconLeft="fa-plus"
                    onClick={handleAddMaterialList}
                  />
                </div>
              </div>
            </div>
            <div
              className={
                styles['add-edit-machine-modal__input__form__material-section__material-list']
              }>
              <Text
                size="s"
                textAlign="center-align"
                label={t('modalMachine.materialsList')}
                color={ColorPropsEnum.GREY}
                bold
                className={
                  styles[
                    'add-edit-machine-modal__input__form__material-section__material-list__title'
                  ]
                }
              />
              {Array.isArray(values?.materials) && values.materials.length > 0 ? (
                values.materials.map((material) => (
                  <div
                    key={material.material}
                    className={
                      styles[
                        'add-edit-machine-modal__input__form__material-section__material-list__content'
                      ]
                    }>
                    <Text
                      size="s"
                      label={t(`material.${material.material}`, { ns: 'component' })}
                      color={ColorPropsEnum.GREY}
                      className={
                        styles[
                          'add-edit-machine-modal__input__form__material-section__material-list__content__material'
                        ]
                      }
                    />
                    <Divider
                      direction="vertical"
                      className={
                        styles[
                          'add-edit-machine-modal__input__form__material-section__material-list__content__divider'
                        ]
                      }
                    />
                    <Text
                      size="s"
                      label={material.brandReference}
                      color={ColorPropsEnum.GREY}
                      className={
                        styles[
                          'add-edit-machine-modal__input__form__material-section__material-list__content__material'
                        ]
                      }
                    />
                    <Text
                      size="s"
                      label={`(${material.thickness})`}
                      color={ColorPropsEnum.GREY}
                      className={
                        styles[
                          'add-edit-machine-modal__input__form__material-section__material-list__content__thickness'
                        ]
                      }
                    />
                  </div>
                ))
              ) : errors.materials ? (
                <Text
                  size="xs"
                  textAlign="center-align"
                  label={t('modalMachine.materialsEmpty')}
                  color={ColorPropsEnum.DANGER}
                />
              ) : (
                <Text
                  size="xs"
                  textAlign="center-align"
                  label={t('modalMachine.noMaterial')}
                  color={ColorPropsEnum.GREY}
                />
              )}
            </div>
          </div>
          <div className={styles['add-edit-machine-modal__second-section']}>
            {/*** Modeling parameters ***/}
            <Text
              size="m"
              textAlign="center-align"
              label={t('modalMachine.configurationTitle')}
              color={ColorPropsEnum.GREY}
              bold
              className={styles['add-edit-machine-modal__input__form__configuration-helper']}
            />
            <div className={styles['add-edit-machine-modal__input__form__configuration-section']}>
              {renderNumberInput({
                name: 'axialCementGap',
                label: 'modalMachine.axialCementGap',
                enumValues: AxialCementGapEnum,
                className:
                  styles['add-edit-machine-modal__input__form__configuration-section__input-number']
              })}
              {renderNumberInput({
                name: 'occlusalCementGap',
                label: 'modalMachine.occlusalCementGap',
                enumValues: OcclusalCementGapEnum,
                className:
                  styles['add-edit-machine-modal__input__form__configuration-section__input-number']
              })}
              {renderNumberInput({
                name: 'retentionGap',
                label: 'modalMachine.retentionGap',
                enumValues: RetentionGapEnum,
                className:
                  styles['add-edit-machine-modal__input__form__configuration-section__input-number']
              })}
              {renderNumberInput({
                name: 'retentionZoneWidth',
                label: 'modalMachine.retentionZoneWidth',
                enumValues: RetentionZoneWidthEnum,
                className:
                  styles['add-edit-machine-modal__input__form__configuration-section__input-number']
              })}
              {renderNumberInput({
                name: 'occlusalDistance',
                label: 'modalMachine.occlusalDistance',
                enumValues: OcclusalDistanceEnum,
                className:
                  styles['add-edit-machine-modal__input__form__configuration-section__input-number']
              })}
              {renderNumberInput({
                name: 'proximalDistance',
                label: 'modalMachine.proximalDistance',
                enumValues: ProximalDistanceEnum,
                className:
                  styles['add-edit-machine-modal__input__form__configuration-section__input-number']
              })}
            </div>
            <div className={styles['add-edit-machine-modal__second-section__checkbox-section']}>
              <Checkbox
                label={t('modalMachine.isFirstStepModeling')}
                onClick={() => handleCheck('isFirstStepModeling')}
                isChecked={values.isFirstStepModeling}
                name="isFirstStepModeling"
                data-cy="first-step-modeling-checkbox"
              />
            </div>
            <Divider className={styles['add-edit-machine-modal__divider']} />
            <div className={styles['add-edit-machine-modal__second-section__image-section']}>
              <img
                className={styles['add-edit-machine-modal__second-section__image-section__image']}
                src="/image/machine-config-schema.svg"
                alt="machine configuration"
              />
            </div>
          </div>
        </div>
        <Button
          label={t('action.create', { ns: 'common' })}
          type="submit"
          variant={ColorPropsEnum.SUCCESS}
          isLoading={isCreating}
          iconLeft="fa-check"
          className={styles['add-edit-machine-modal__input__form__submit']}
          data-cy={'add-edit-machine-modal-submit'}
        />
      </form>
    </Modal>
  );
};

export default AddEditMachineModal;
