import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { WizardPicto, IconButton } from '@platform-storybook/circlestorybook';
import styles from './edit-order-page.module.scss';
import PatientFilesStep from './patient-files-step/PatientFilesStep.tsx';
import PlanStep from './plan-step/PlanStep.tsx';
import DesignStep from './design-step/DesignStep.tsx';
import { WizardPictoStep } from '../../../models/navigation.tsx';
import ExportStep from './export-step/ExportStep.tsx';
import ExitModal from '../../../features/exit-modal/ExitModal.tsx';
import { getWizardSteps } from './edit-order-page.utils.ts';
import { useGetOneOrderQuery } from '../../../services/orders-api.services.ts';
import { useAppSelector } from '../../../hooks/hooks.tsx';
import { isOrderStreamingSelector } from '../../../store/orders/orders.selectors.tsx';

const EditOrderPage = () => {
  const { orderNumber } = useParams();
  const [selectedStepIndex, setSelectedStepIndex] = useState<number>(0);
  const [wizardSteps, setWizardSteps] = useState<Array<WizardPictoStep>>(getWizardSteps());
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const isOrderStreaming = useAppSelector(isOrderStreamingSelector);
  const { data: orderInfo, isLoading: isOrderLoading } = useGetOneOrderQuery(orderNumber as string);

  const navigateNextStep = () => {
    const newStepIndex = selectedStepIndex + 1;
    setSelectedStepIndex(newStepIndex);
    setWizardSteps(getWizardSteps(newStepIndex));
  };

  return (
    orderNumber && (
      <div className={styles['edit-order-page']}>
        <WizardPicto options={wizardSteps} className={styles['edit-order-page__wizard']} />
        <div className={styles['edit-order-page__content']}>
          {!isOrderLoading && orderInfo?.orderNumber && selectedStepIndex === 0 && (
            <PatientFilesStep orderNumber={orderNumber as string} nextCallback={navigateNextStep} />
          )}
          {!isOrderLoading && orderInfo?.orderNumber && selectedStepIndex === 1 && (
            <PlanStep orderNumber={orderNumber as string} nextCallback={navigateNextStep} />
          )}
          {!isOrderLoading && orderInfo?.orderNumber && selectedStepIndex === 2 && (
            <DesignStep orderNumber={orderNumber as string} nextCallback={navigateNextStep} />
          )}
          {!isOrderLoading && selectedStepIndex === 3 && (
            <ExportStep orderNumber={orderNumber as string} />
          )}
          {selectedStepIndex !== 3 && !isOrderStreaming && (
            // The quit button is managed by platform on pages where there is no streaming.
            <div className={styles['edit-order-page__content__exit-button']}>
              <IconButton
                faIconClass="xmark"
                radius="full"
                category="outlined"
                size="m"
                iconSize="xs"
                iconStyle="light"
                color="primary"
                onClick={() => setIsModalOpened(true)}
              />
              <ExitModal
                orderNumber={orderInfo?.orderNumber}
                patientReference={orderInfo?.patient?.reference}
                isOpened={isModalOpened}
                redirectUrl={'/treatments'}
                onClose={() => setIsModalOpened(false)}
              />
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default EditOrderPage;
