import styles from './circular-progress.module.scss';
import { Text } from '@platform-storybook/circlestorybook';
import { useEffect, useState, useRef } from 'react';
import { ColorPropsEnum } from '../../../../../enum/color.enum.ts';

type CircularProgressionProps = {
  max: number;
  stopAt?: number;
  durationInSeconds: number;
  helperText?: string;
};

// Here, to display progression, we'll "cheat" & create the equivalent of a dashed border
// The "border" will represent our progress
// Then we'll display one dash, that will start from where progress ends & go all the way around to represent the track
const CircularProgress = ({
  max,
  stopAt,
  durationInSeconds,
  helperText
}: CircularProgressionProps) => {
  // LOCAL CONSTANTS & STATES
  const min = 0;
  // In pixels
  const size = 100;
  const strokeWidth = 10;
  const center = size / 2;
  const radius = center - strokeWidth;
  const circumference = 2 * 3.14 * radius; // 2 * pie * radius
  // The stroke-dashoffset property works in conjunction with stroke-dasharray to specify where the dash begins.
  // As this number increases, the dashes will appear further along the path
  // Here, the offset is the length represented by our progress, the "border"
  const getStrokeDashOffset = () => {
    return circumference * ((max - progress) / max);
  };
  // The stroke-dasharray property allows us to give the SVG stroke a dash-like look.
  // A higher dasharray value indicates more space between dashes in the stroke
  // To make computations easier, it's ok to set it to the circumference
  const strokeDasharray = circumference;
  const intervalRef = useRef<ReturnType<typeof setInterval>>();
  const [progress, setProgress] = useState(min);
  const [strokeDashOffset, setStrokeDashOffset] = useState(getStrokeDashOffset());

  // USE EFFECTS
  useEffect(() => {
    // Compute the delay between progression incrementation
    //  -> Every x milliseconds (x = the duration / the number of steps, in seconds) * 1000 to have milliseconds
    const timeoutStepDuration = (durationInSeconds / (stopAt ?? max)) * 1000;

    // Every x milliseconds, increment progression
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setProgress((prevState) => ++prevState);
    }, timeoutStepDuration);

    // Clear interval on unmount
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    // Once we've reached the point where we want to stop, clear interval
    if (progress >= (stopAt ?? max)) {
      clearInterval(intervalRef.current);
    }

    setStrokeDashOffset(getStrokeDashOffset());
  }, [progress]);

  // DISPLAY COMPONENT
  // All design only properties are set in SVG (gradient color, ...)
  // All data depending having an impact on calculations (width, height, radius, stroke width,...) are set here
  // To ensure the fact that calculations will always work no matter the size of the component
  // And to make it more maintainable
  return (
    <div className={styles['circular-progress']} style={{ height: size }}>
      <div className={styles['circular-progress__container']}>
        <svg
          className={styles['circular-progress__container__svg']}
          style={{ width: size, height: size }}>
          <defs>
            <linearGradient id="gradient">
              <stop
                offset="0%"
                className={styles['circular-progress__container__svg__defs__gradient__start']}
              />
              <stop
                offset="25%"
                className={styles['circular-progress__container__svg__defs__gradient__first-stop']}
              />
              <stop
                offset="75%"
                className={styles['circular-progress__container__svg__defs__gradient__second-stop']}
              />
              <stop
                offset="100%"
                className={styles['circular-progress__container__svg__defs__gradient__end']}
              />
            </linearGradient>
            <radialGradient
              id="rotatingPulse"
              cx=".66"
              fx=".66"
              cy=".3125"
              fy=".3125"
              gradientTransform="scale(1.5)">
              <stop
                offset="0"
                className={styles['circular-progress__container__svg__defs__rotating-pulse']}
                stopOpacity="0"></stop>
              <stop
                offset="1"
                className={styles['circular-progress__container__svg__defs__rotating-pulse']}
                stopOpacity="0.75"></stop>
            </radialGradient>
          </defs>
          <circle
            className={styles['circular-progress__container__svg__track']}
            r={radius}
            cx={center}
            cy={center}
            strokeWidth={strokeWidth}
          />
          <circle
            className={styles['circular-progress__container__svg__progress']}
            stroke="url(#gradient)"
            strokeDasharray={strokeDasharray}
            strokeDashoffset={strokeDashOffset}
            r={radius}
            cx={center}
            cy={center}
            strokeWidth={strokeWidth}
          />
          <circle
            className={styles['circular-progress__container__svg__progress__rotating-pulse']}
            stroke="url(#rotatingPulse)"
            strokeWidth={strokeWidth}
            cx={center}
            cy={center}
            r={radius}>
            <animateTransform
              type="rotate"
              attributeName="transform"
              calcMode="spline"
              dur="3"
              values="0;360"
              keyTimes="0;1"
              keySplines="0 0 1 1"
              repeatCount="indefinite"
            />
          </circle>
        </svg>
        <Text
          className={styles['circular-progress__label']}
          label={`${progress}%`}
          color={ColorPropsEnum.PRIMARY}
          size="s"
        />
      </div>
      <Text
        className={styles['circular-progress__label']}
        label={helperText}
        color={ColorPropsEnum.PRIMARY}
        size="s"
      />
    </div>
  );
};

export default CircularProgress;
