import { useState } from 'react';
import {
  useUploadToStorageMutation,
  useLazyGetOneDownloadableFileQuery
} from '../services/orders-api.services';
import { OrderFile } from '../models/order';
import { useDownloadFileFromStorageMutation } from '../services/files-api.services';
import { useAppDispatch } from './hooks';
import { ordersActions } from '../store/orders/orders.reducer';
import { feedbackActions } from '../store/feedback/feedback.reducer';
import { ToastType } from '../enum/feedback';
import { getMessageError } from '../utils/utils';
import { encodeUrl } from '../utils/file.utils';

export const useFiles = () => {
  const [uploadToStorage] = useUploadToStorageMutation();
  const [isUploadFilesToStorageSuccess, setIsUploadFilesToStorageSuccess] =
    useState<boolean>(false);
  const [isUploadFilesToStorageError, setIsUploadFilesToStorageError] = useState<boolean>(false);
  const [getOneDownloadableFile] = useLazyGetOneDownloadableFileQuery();
  const [downloadFromStorage] = useDownloadFileFromStorageMutation();
  const dispatch = useAppDispatch();

  const setLoaderFile = (file: OrderFile, isLoading: boolean) => {
    dispatch(ordersActions.setOneFile({ ...file, isLoading }));
  };

  const displayErrorToast = (error: unknown) => {
    dispatch(
      feedbackActions.setToast({
        message: getMessageError(error),
        type: ToastType.DANGER
      })
    );
  };

  const uploadFilesToStorage = async (orderFiles: OrderFile[]): Promise<void> => {
    try {
      dispatch(ordersActions.setLoadingFiles(true));
      await Promise.all(
        orderFiles.map(async (newFileToUpload) => {
          setLoaderFile(newFileToUpload, true);
          await uploadToStorage({
            url: newFileToUpload.uploadUrl!,
            file: newFileToUpload.data!
          }).unwrap();
          setLoaderFile(newFileToUpload, false);
        })
      );
      setIsUploadFilesToStorageSuccess(true);
    } catch (error) {
      setIsUploadFilesToStorageError(true);
      displayErrorToast(error);
    } finally {
      dispatch(ordersActions.setLoadingFiles(false));
    }
  };

  const loadOrderFilesData = async (
    orderNumber: string,
    filesToDownload: OrderFile[]
  ): Promise<void> => {
    try {
      if (filesToDownload.length === 0) return;
      dispatch(ordersActions.setLoadingFiles(true));
      await Promise.all(
        filesToDownload.map(async (fileToDownload) => {
          try {
            setLoaderFile(fileToDownload, true);
            // 1 - Get download file link
            const downloadableFile = await getOneDownloadableFile({
              orderNumber,
              fileId: fileToDownload.id!
            }).unwrap();

            if (downloadableFile?.link) {
              dispatch(ordersActions.setOneFile({ ...downloadableFile, isLoading: true }));
              // 2 - Download file from storage and save it in store
              const blobFile = await downloadFromStorage({
                url: encodeUrl(downloadableFile.link)
              }).unwrap();
              dispatch(
                ordersActions.setOneFile({
                  ...downloadableFile,
                  data: blobFile
                    ? new File([blobFile], downloadableFile.fileName)
                    : downloadableFile.data,
                  fileLabel: downloadableFile.fileLabel,
                  isLoading: false
                })
              );
            }
          } catch (error) {
            setLoaderFile(fileToDownload, false);
            displayErrorToast(error);
          }
        })
      );
    } finally {
      dispatch(ordersActions.setLoadingFiles(false));
    }
  };

  return {
    loadOrderFilesData,
    uploadFilesToStorage,
    isUploadFilesToStorageSuccess,
    isUploadFilesToStorageError
  };
};
