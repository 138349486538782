import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FilesList,
  FileUpload,
  Text,
  Tooltip,
  TooltipContent,
  Viewer
} from '@platform-storybook/circlestorybook';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks.tsx';
import { Order, OrderFile, OrderForUpdate } from '../../../../models/order';
import {
  isLoadingFilesSelector,
  orderFilesSelector
} from '../../../../store/orders/orders.selectors';
import { ordersActions } from '../../../../store/orders/orders.reducer';
import styles from './patient-files.module.scss';
import useForm from '../../../../hooks/useForm.tsx';
import {
  areSameFileArrays,
  areSameFiles,
  checkUploadingFile,
  fileLabelsAllowed,
  getTextureFile,
  isThumbprintExtension,
  mapFileToOrderFile
} from '../../../../utils/file.utils.ts';
import { useGetCommonTypesQuery } from '../../../../services/common-types-api.services.ts';
import {
  useGenerateThumbnailsOrderMutation,
  useGetOneOrderQuery,
  usePatchOrderMutation,
  usePredictPatientFileLabelMutation
} from '../../../../services/orders-api.services.ts';
import { FileLabelEnum } from '../../../../enum/file-label.ts';
import { ErrorCode } from '../../../../enum/error.ts';
import { FileLabel } from '../../../../models/common-types.tsx';
import { ColorPropsEnum } from '../../../../enum/color.enum.ts';
import i18next from 'i18next';
import { useFiles } from '../../../../hooks/useFiles.tsx';

type Props = {
  orderNumber: string;
  nextCallback: () => void;
};
const PatientFilesStep = ({ orderNumber, nextCallback }: Props) => {
  const { t } = useTranslation(['order']);
  const dispatch = useAppDispatch();

  const orderFiles = useAppSelector(orderFilesSelector);
  const isLoadingFiles = useAppSelector(isLoadingFilesSelector);

  const [errorFiles, setErrorFiles] = useState<string>('');
  const [errorLabels, setErrorLabels] = useState<string>('');
  const [file3dToDisplay, setFile3dToDisplay] = useState<OrderFile>();
  const [fileTextureToDisplay, setFileTextureToDisplay] = useState<OrderFile>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [hasUploaded, setHasUploaded] = useState<boolean>(false);

  const { data: order } = useGetOneOrderQuery(orderNumber as string);
  const { data: commonTypes } = useGetCommonTypesQuery();
  const [predictLabel] = usePredictPatientFileLabelMutation();
  const [patchOrder, { data: patchedOrder, isSuccess: isSuccessPatchOrder }] =
    usePatchOrderMutation();
  const [generateThumbnailsOrder] = useGenerateThumbnailsOrderMutation();

  const {
    uploadFilesToStorage,
    isUploadFilesToStorageSuccess,
    isUploadFilesToStorageError,
    loadOrderFilesData
  } = useFiles();

  const upperFileLength = orderFiles.filter(
    (file) => file.fileLabel === FileLabelEnum.UPPER
  )?.length;
  const lowerFileLength = orderFiles.filter(
    (file) => file.fileLabel === FileLabelEnum.LOWER
  )?.length;
  const missingFileLabelsLength = orderFiles.filter(
    (file) => !file.fileLabel || !fileLabelsAllowed.includes(file.fileLabel)
  )?.length;
  const isDisabledSubmitButton =
    upperFileLength === 0 || lowerFileLength === 0 || missingFileLabelsLength > 0;

  // Check if order files in store are empty. Return true if only isLoading is set
  const areEmptyOrderFilesStore =
    !orderFiles?.length || orderFiles?.some((file) => Object.keys(file).length <= 1);

  // Load files data in they exist in database and save them in reducer
  useEffect(() => {
    const patientFilesFromOrder = order?.patient?.diagnostic?.patientFiles;
    if (
      order &&
      patientFilesFromOrder &&
      patientFilesFromOrder.length > 0 &&
      areEmptyOrderFilesStore
    ) {
      loadOrderFilesData(order.orderNumber, order.patient?.diagnostic?.patientFiles as OrderFile[]);
      dispatch(ordersActions.setFiles(patientFilesFromOrder));
    }
  }, [order?.patient.diagnostic.patientFiles]);

  // Check uploaded files
  useEffect(() => {
    resetErrors();
    if (hasUploaded) {
      displayErrors();
    }
  }, [orderFiles]);

  // Display files in viewer
  useEffect(() => {
    if (!isLoadingFiles && orderFiles[orderFiles.length - 1]?.data) {
      displayFile(orderFiles, orderFiles[orderFiles.length - 1]);
    }
  }, [isLoadingFiles, orderFiles]);

  // Order patched -> Upload files to storage
  useEffect(() => {
    if (isSuccessPatchOrder) {
      const patientFiles: OrderFile[] = patchedOrder?.patient?.diagnostic
        ?.patientFiles as OrderFile[];
      if (patientFiles && patientFiles.length) {
        // we need to send all patientFiles, even if there are already uploaded. We received from backend does not contain blob file => we need to reset data to upload the files just after
        const patientFilesToUpload = patientFiles
          .filter((file) => file.uploadUrl)
          .map((file) => {
            const data = orderFiles?.find(
              (orderFile) => file && areSameFiles(file, orderFile)
            )?.data;
            return { ...file, data };
          });
        uploadFilesToStorage(patientFilesToUpload);
      }
    }
  }, [isSuccessPatchOrder]);

  // Order patched and files uploaded to storage -> Generate thumbnails and go to nex step
  useEffect(() => {
    if (isSuccessPatchOrder && isUploadFilesToStorageSuccess) {
      onSuccessNextStep();
    }
  }, [isSuccessPatchOrder, isUploadFilesToStorageSuccess]);

  useEffect(() => {
    if (isUploadFilesToStorageError) {
      setIsSaving(false);
    }
  }, [isUploadFilesToStorageError]);

  const saveOrder = async (): Promise<void> => {
    // Here we make sure to remove the id from diagnostic in order to update it
    const diagnosticData = order?.patient?.diagnostic ?? {};
    const diagnostic = Object.fromEntries(
      Object.entries(diagnosticData).filter(([key]) => key !== 'id')
    );

    if (diagnosticData.patientFiles && areSameFileArrays(diagnosticData.patientFiles, orderFiles)) {
      // do not patch the command if the files in the store are the same in the backend (for example if i upload files from the home page, and i did not update them)
      onSuccessNextStep();
      return;
    }

    const orderToUpdate: OrderForUpdate = {
      orderNumber: (order as Order).orderNumber,
      patient: {
        diagnostic: { ...diagnostic, patientFiles: orderFiles }
      }
    };
    await patchOrder(orderToUpdate);
  };

  const submit = async (): Promise<void> => {
    setIsSaving(true);
    await saveOrder();
  };

  const onSuccessNextStep = () => {
    if (order) {
      generateThumbnailsOrder(order.orderNumber);
    }
    setIsSaving(false);
    nextCallback();
  };

  const resetErrors = (): void => {
    setErrorFiles('');
    setErrorLabels('');
  };

  const displayErrors = () => {
    if (upperFileLength == 0 || lowerFileLength == 0) {
      setErrorFiles(ErrorCode.ORDERS_FILE_MANDATORY);
    }
    let error: string | undefined;
    if (missingFileLabelsLength > 0) {
      error = ErrorCode.ORDERS_FILE_LABEL_MISSING;
    } else if (upperFileLength > 1) {
      error = ErrorCode.ORDERS_FILE_LABEL_UPPER;
    } else if (lowerFileLength > 1) {
      error = ErrorCode.ORDERS_FILE_LABEL_LOWER;
    }
    if (error) {
      setErrorLabels(error);
    }
  };

  const deleteFile = async (fileToDelete: OrderFile): Promise<void> => {
    const orderFilesCopy: OrderFile[] = Object.assign([], orderFiles);
    const fileIndex = orderFilesCopy.findIndex((file) => file.fileName === fileToDelete.fileName);
    if (fileIndex > -1) {
      orderFilesCopy.splice(fileIndex, 1);
      dispatch(ordersActions.setFiles(orderFilesCopy));
    }
    await displayFile(orderFilesCopy, orderFilesCopy[orderFilesCopy.length - 1]);
  };

  const onUploadFiles = async (newFilesToUpload: File[]): Promise<void> => {
    if (!hasUploaded) {
      setHasUploaded(true);
    }
    dispatch(ordersActions.setLoadingFiles(true));
    const orderFilesCopy: OrderFile[] = Object.assign([], orderFiles);
    const newFilesMap: OrderFile[] = [];

    // map files, add loader and check them
    for (const newFileToUpload of newFilesToUpload) {
      const orderFile = mapFileToOrderFile(newFileToUpload, true);
      newFilesMap.push(orderFile);

      if (!checkUploadingFile(orderFile, orderFiles, dispatch)) {
        dispatch(ordersActions.setLoadingFiles(false));
        return;
      }
    }
    dispatch(ordersActions.setFiles([...orderFiles, ...newFilesMap]));

    // Add label and display in viewer
    for (const newFileToUpload of newFilesToUpload) {
      const orderFile = mapFileToOrderFile(newFileToUpload, false);
      const prediction = await predictLabel({
        fileName: `${orderFile.fileName}.${orderFile.extension}`
      });
      if ('data' in prediction && fileLabelsAllowed.includes(prediction.data as FileLabelEnum)) {
        orderFile.fileLabel = prediction.data;
      }

      orderFilesCopy.push(orderFile);
    }

    dispatch(ordersActions.setFiles(orderFilesCopy));
    await displayFile(orderFilesCopy, orderFilesCopy[orderFilesCopy.length - 1]);
    dispatch(ordersActions.setLoadingFiles(false));
  };

  const displayFile = async (orderFiles: OrderFile[], fileToDisplay: OrderFile): Promise<void> => {
    const files3D = orderFiles.filter(
      (file) => isThumbprintExtension(file.extension) && file === fileToDisplay
    );
    const file3D = files3D?.length ? files3D[0] : undefined;
    setFile3dToDisplay(file3D);
    const fileTexture = await getTextureFile(orderFiles, file3D);
    setFileTextureToDisplay(fileTexture);
  };

  const labels = commonTypes?.fileLabels
    ?.filter((fileLabel: FileLabel) => fileLabelsAllowed.includes(fileLabel.code))
    ?.map((fileLabel: FileLabel) => {
      return {
        label: t(`editOrder.patientFiles.fileUpload.labels.${fileLabel.code}`),
        value: fileLabel.code,
        image: fileLabel.imageUrl
      };
    });

  const handleLabelChangeValue = (file: OrderFile, newValue: string): void => {
    const newFilesWithLabel = orderFiles.map((orderFile) =>
      orderFile.fileName === file.fileName ? { ...orderFile, fileLabel: newValue } : orderFile
    ) as OrderFile[];

    dispatch(ordersActions.setFiles(newFilesWithLabel));
  };

  const labelerConfig = {
    coverText: t('editOrder.patientFiles.fileUpload.labelerCover'),
    isLoading: false,
    onChange: (file: OrderFile, label: string) => handleLabelChangeValue(file, label),
    maxHeight: 350
  };

  const submitButton = () => {
    return (
      <Button
        label={t('editOrder.patientFiles.action')}
        type="submit"
        variant={ColorPropsEnum.SUCCESS}
        iconLeft="fa-chevron-right"
        isLoading={isSaving}
        isDisabled={isDisabledSubmitButton}
        data-cy="edit-order-patientFiles-action"
      />
    );
  };
  const { handleSubmit } = useForm({}, submit);

  return (
    <div className={styles['patient-files']}>
      <div data-cy="patient-files-intro" className={styles['patient-files__intro']}>
        <Text
          size="l"
          color={ColorPropsEnum.GREY}
          label={t('editOrder.patientFiles.intro.text1')}
        />
        <Text
          size="l"
          color={ColorPropsEnum.GREY}
          label={t('editOrder.patientFiles.intro.text2')}
        />
      </div>
      <form onSubmit={handleSubmit} className={styles['patient-files__form']}>
        <Box color="white" className={styles['patient-files__form__box-file']}>
          <div className={styles['patient-files__form__content']}>
            <div className={styles['patient-files__form__content__files']}>
              <FileUpload
                title={t('editOrder.patientFiles.fileUpload.uploadBoxTitle')}
                subtitle={t('editOrder.patientFiles.fileUpload.uploadBoxSubtitle')}
                onUploadFile={(newFiles: File[]) => onUploadFiles(newFiles)}
                className={
                  !isLoadingFiles && errorFiles
                    ? styles['patient-files__form__content__error']
                    : styles['patient-files__form__content__files__upload']
                }
                language={i18next.language}
              />
              {!isLoadingFiles && (errorFiles || errorLabels) && (
                <Text
                  label={
                    t(`${errorFiles}`, { ns: 'error' }) + ' ' + t(`${errorLabels}`, { ns: 'error' })
                  }
                  color={ColorPropsEnum.DANGER}
                  className={styles['patient-files__form__content__error-text']}
                />
              )}
              <FilesList
                data-cy="files-list"
                files={orderFiles}
                readonly={false}
                labelsList={labels}
                labelerProps={{ ...labelerConfig, variant: errorLabels ? 'danger' : 'default' }}
                fileActions={(file: OrderFile) => [
                  [
                    {
                      id: 'delete',
                      disabled: false,
                      label: t('action.delete', { ns: 'common' }),
                      type: 'button',
                      onClick: () => {
                        deleteFile(file);
                      }
                    }
                  ]
                ]}
                onClickCallback={(selectedFile: OrderFile) => displayFile(orderFiles, selectedFile)}
                initialSelectedIndex={orderFiles?.length - 1}
                className={styles['patient-files__form__content__files__list']}
              />
            </div>
            <div className={styles['patient-files__form__content__aside']}>
              <div className={styles['patient-files__form__content__aside__viewer']}>
                <Viewer
                  noFileLabel={t('editOrder.patientFiles.noFile')}
                  file3D={file3dToDisplay}
                  fileTexture={fileTextureToDisplay}
                  isLoading={isLoadingFiles}
                  className={styles['patient-files__form__content__aside__viewer__content']}
                />
              </div>
            </div>
          </div>
        </Box>
        <div className={styles['patient-files__form__button']}>
          {isDisabledSubmitButton ? (
            <Tooltip>
              <TooltipContent>{t('editOrder.patientFiles.tooltip')}</TooltipContent>
              {submitButton()}
            </Tooltip>
          ) : (
            submitButton()
          )}
        </div>
      </form>
    </div>
  );
};
export default PatientFilesStep;
