import { useEffect, useState } from 'react';
import styles from './register-page.module.scss';
import { Box, Button, Checkbox, Link, Text, TextField } from '@platform-storybook/circlestorybook';
import { useAppDispatch } from '../../../hooks/hooks.tsx';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { feedbackActions } from '../../../store/feedback/feedback.reducer';
import { ColorPropsEnum } from '../../../enum/color.enum';
import Onboarding from '../login-page/onboarding/Onboarding.tsx';
import { useAddUserMutation } from '../../../services/user.api.services.ts';
import { Country, DentalNotation, DentistRole, Language } from '../../../enum/user.ts';
import useForm from '../../../hooks/useForm.tsx';
import { getMessageError, publicDocumentsUrl } from '../../../utils/utils.tsx';
import { EstablishmentType } from '../../../enum/establishment.ts';
import { ToastType } from '../../../enum/feedback.ts';
import { useCreateClinicMutation } from '../../../services/establishments-api.services.ts';
import { getUrlPrefix } from '../../../utils/partner.utils.ts';
import { CommonDataValue } from '../../../enum/common.enum.ts';

const RegisterPage = () => {
  const { t } = useTranslation(['auth']);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [addUser, { isError: isErrorAddUser, isSuccess: isUserAdded }] = useAddUserMutation();
  const [createClinic] = useCreateClinicMutation();
  const [isCreatingDentist, setIsCreatingDentist] = useState<boolean>(false);

  const { partnerCode } = useParams();

  // useEffect dentist created
  useEffect(() => {
    if (isUserAdded) {
      setIsCreatingDentist(false);
      dispatch(
        feedbackActions.setToast({
          message: t('toast.registerSuccess'),
          type: ToastType.SUCCESS
        })
      );
      handleClickLogin();
    }
  }, [isUserAdded]);

  // useEffect when error reinitialize isCreatingDentist
  useEffect(() => {
    if (isErrorAddUser) {
      setIsCreatingDentist(false);
    }
  }, [isErrorAddUser]);

  const addNewUserAndClinic = async () => {
    // TODO: (Georges, Robin, AK)  on a choisit de mettre "empty" pour le moment plutôt que de ne pas mettre de clinique du tout,
    //  car cela impliquerait trop de changement back et front avant l'IDS, mais que l'on changera peut-être ça dans le futur
    const newClinic = {
      name: CommonDataValue.EMPTY,
      type: EstablishmentType.CLINIC,
      address: {
        address: CommonDataValue.EMPTY,
        zipCode: CommonDataValue.EMPTY,
        city: CommonDataValue.EMPTY,
        country: Country.FR
      }
    };

    await createClinic(newClinic)
      .unwrap()
      .then(async (data) => {
        // This state is used to trigger the dentist creation after a clinic creation, see useEffect related to createdClinicId
        if (data?.id) {
          // create the user after clinic creation
          await addNewUser(data.id);
        }
      })
      .catch((error) => {
        setIsCreatingDentist(false);
        dispatch(
          feedbackActions.setToast({
            message: getMessageError(error),
            type: ToastType.DANGER
          })
        );
      });
  };

  const addNewUser = async (clinicId: number) => {
    const newUser = {
      role: DentistRole.DENTIST,
      email: values.email as string,
      firstName: values.firstName as string,
      lastName: values.lastName as string,
      password: values.password as string,
      phoneNumber: '07000000',
      language: Language.en_GB,
      dentalNotation: DentalNotation.US,
      isAllowedToOrder: true,
      laboratory: {
        id: 1
      },
      clinic: {
        id: clinicId
      },
      partnerCode
    };
    await addUser(newUser);
  };

  const onSubmit = async () => {
    setIsCreatingDentist(true);
    await addNewUserAndClinic();
  };

  const initUser = {
    email: '',
    firstName: '',
    lastName: '',
    termsChecked: false
  };

  const handleClickLogin = () => navigate(`${getUrlPrefix(partnerCode)}/login`);

  const { values, errors, handleSubmit, handleBlur, handleChange, handleCheck } = useForm(
    initUser,
    onSubmit
  );

  const LabelCheckbox = () => (
    <div
      className={
        styles['register-page__section__box__aside__form__checkbox-container__checkbox-label']
      }>
      <Text
        color={
          errors.termsChecked && !values.termsChecked
            ? ColorPropsEnum.DANGER
            : ColorPropsEnum.DEFAULT
        }
        size="s"
        label={t('rules.text1')}
      />
      <Link
        href={`${publicDocumentsUrl}CIRCLE-CGU.pdf`}
        target="_blank"
        size="s"
        label={t('rules.link1')}
      />
      <Text
        color={
          errors.termsChecked && !values.termsChecked
            ? ColorPropsEnum.DANGER
            : ColorPropsEnum.DEFAULT
        }
        size="s"
        label={t('rules.text2')}
      />
      <Link
        href={`${publicDocumentsUrl}CIRCLE-PRIVACY-POLICY.pdf`}
        target="_blank"
        size="s"
        label={t('rules.link2')}
      />
    </div>
  );

  return (
    <div className={styles['register-page']}>
      <section className={styles['register-page__section']}>
        <Box
          padding="spacing-0"
          className={styles['register-page__section__box']}
          radius="large"
          color={ColorPropsEnum.WHITE}>
          <Onboarding partnerCode={partnerCode} />
          <div className={styles['register-page__section__box__aside']}>
            <Text
              label={t('signupTitle')}
              type="title"
              size="m"
              textAlign="center-align"
              color={ColorPropsEnum.PRIMARY}
              className={styles['register-page__section__box__aside__title']}
              bold={true}
            />
            <form
              id="register-page__section__form"
              className={styles['register-page__section__box__aside__form']}
              onSubmit={handleSubmit}>
              <TextField
                id={'firstName'}
                label={t('firstName')}
                value={values.firstName}
                name="firstName"
                type={'text'}
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={errors?.firstName ? t('error.emptyFirstName') : undefined}
                variant={errors?.firstName ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
                className={styles['register-page__section__box__aside__form__input']}
                placeholder={t('firstName')}
                radius="full"
                data-cy="register-form-firstname"
              />
              <TextField
                id={'lastName'}
                label={t('lastName')}
                value={values.lastName}
                name="lastName"
                type={'text'}
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={errors?.lastName ? t('error.emptyLastName') : undefined}
                variant={errors?.lastName ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
                className={styles['register-page__section__box__aside__form__input']}
                placeholder={t('lastName')}
                radius="full"
                data-cy="register-form-lastname"
              />
              <TextField
                label={t('email')}
                id={'email'}
                name="email"
                value={values.email}
                type={'email'}
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={errors?.email ? t('error.emptyEmail') : undefined}
                variant={errors?.email ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
                className={styles['register-page__section__box__aside__form__input']}
                placeholder="email@circle.com"
                radius="full"
                data-cy="register-form-email"
              />
              <div
                className={styles['register-page__section__box__aside__form__checkbox-container']}>
                <Checkbox
                  className={
                    styles['register-page__section__box__aside__form__checkbox-container__checkbox']
                  }
                  onClick={() => handleCheck('termsChecked')}
                  variant={
                    errors.termsChecked && !values.termsChecked
                      ? ColorPropsEnum.DANGER
                      : ColorPropsEnum.DEFAULT
                  }
                  isChecked={values.termsChecked}
                  name="termsChecked"
                  data-cy="register-form-terms"
                />
                <LabelCheckbox />
              </div>

              <div className={styles['register-page__section__box__aside__form__button']}>
                <Button
                  label={t('createAccountButton')}
                  isLoading={isCreatingDentist}
                  iconLeft="fa-check"
                  type="submit"
                  variant={ColorPropsEnum.SUCCESS}
                  className={styles['register-page__section__box__aside__form__button__submit']}
                  data-cy="register-form-submit-button"
                />
              </div>
            </form>
          </div>
        </Box>

        <footer className={styles['register-page__section__footer']}>
          <Text
            className={styles['register-page__section__footer__text']}
            label={t('haveAccount')}
            size="s"
            color={ColorPropsEnum.GREY}
          />
          <Button
            data-cy="register-link"
            category="outlined"
            label={t('loginRedirectionButton')}
            size="s"
            onClick={handleClickLogin}
          />
        </footer>
      </section>
    </div>
  );
};

export default RegisterPage;
