import { useTranslation } from 'react-i18next';
import styles from './onboarding.module.scss';
import { Text } from '@platform-storybook/circlestorybook';
import { ColorPropsEnum } from '../../../../enum/color.enum';

type Props = {
  partnerCode: string | undefined;
};
const Onboarding = ({ partnerCode }: Props) => {
  const { t } = useTranslation(['auth']);

  return (
    <article className={styles['onboarding']}>
      <div className={styles['onboarding__content']}>
        <Text
          label={t('onboarding.hello')}
          className={styles['onboarding__content__title']}
          type="title"
          color={ColorPropsEnum.WHITE}
          size="s"
          bold={true}
        />
        <div>
          <Text
            label={
              partnerCode
                ? t(`onboarding.login.partner-text`, { partner: partnerCode })
                : t(`onboarding.login.text-1`)
            }
            className={styles['onboarding__content__text']}
            color={ColorPropsEnum.WHITE}
          />
        </div>
        {!partnerCode && (
          <>
            <img
              src="/image/register-ipad.png"
              alt="ipad"
              className={styles['onboarding__content__image']}
            />
            <div>
              <Text
                label={t(`onboarding.login.text-2`)}
                bold={true}
                textAlign="center-align"
                color={ColorPropsEnum.WHITE}
                size="s"
              />
            </div>
          </>
        )}
      </div>
    </article>
  );
};

export default Onboarding;
