import { Chips, Header } from '@platform-storybook/circlestorybook';
import { ColorPropsEnum } from '../../enum/color.enum.ts';
import styles from './private-app-header.module.scss';
import PatientReferenceForm from './patient-reference-form/PatientReferenceForm.tsx';
import { useState } from 'react';
import ExitModal from '../exit-modal/ExitModal.tsx';
import { useParams } from 'react-router';
import { useGetOneOrderQuery } from '../../services/orders-api.services.ts';
import { Order } from '../../models/order.tsx';

const PrivateAppHeader = ({ envLabel }: { envLabel: string }) => {
  const { orderNumber } = useParams();
  const { data: order } = useGetOneOrderQuery(orderNumber as string);

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

  return (
    <>
      <ExitModal
        orderNumber={order?.orderNumber}
        patientReference={order?.patient?.reference}
        isOpened={isModalOpened}
        redirectUrl={'/home'}
        onClose={() => setIsModalOpened(false)}
      />
      <Header logoClass="logo-one" color="grey" onLogoClick={() => setIsModalOpened(true)}>
        <div className={styles['private-app-header__content']}>
          {order?.patient?.reference && <PatientReferenceForm order={order as Order} />}
          {envLabel && <Chips firstLabel={envLabel} color={ColorPropsEnum.PRIMARY} />}
        </div>
      </Header>
    </>
  );
};

export default PrivateAppHeader;
